import React from "react";
import AuthContext from "../../../../AuthContext";
import mtzApis from "../../../../services";
import LimitedCharReactQuill from "../../../shared2/reactQuills/LimitedCharReactQuill";
import "./style.css";

const { companyService } = mtzApis;

export default function CompanyGroupBasicInfoViewEdit({ companyGroupId, profileName }) {
  let [companyGroup, setCompanyGroup] = React.useState(null);
  let [editParams, setEditParams] = React.useState({});
  let [companyGroupMember, setCompanyGroupMember] = React.useState(null);
  const editFormToggle = React.useRef(null);

  const me = React.useContext(AuthContext);

  React.useEffect(() => {
    let isMounted = true;

    (async () => {
      let companyGroup;
      if (profileName) {
        const companyGroups = await companyService.getCompanyGroups(
          new URLSearchParams(`profileName=${profileName}`)
        );
        companyGroup = companyGroups && companyGroups.length > 0 ? companyGroups[0] : null;
      }

      if (companyGroup === null) {
        return;
      }

      if (isMounted) setCompanyGroup(companyGroup);

      let company = await companyService.getMyCompany();
      if (company) {
        let companyGroupMembers = await companyService.getCompanyGroupMembers(
          new URLSearchParams(`companyGroupIds=${companyGroup.id}&userIds=${me.userId}`)
        );
        let myCompanyGroupMember =
          companyGroupMembers && companyGroupMembers.length > 0 ? companyGroupMembers[0] : null;
        if (isMounted) setCompanyGroupMember(myCompanyGroupMember);
      }
    })();

    return () => { isMounted = false; };
  }, [profileName, companyGroupId]);

  const editGroup = async e => {
    e.preventDefault();

    if (Object.keys(editParams).length === 0) {
      alert('Nothing changed');
      return;
    }

    const ok = await window.createMtzConfirm('Are you sure?');
    if (!ok) return;

    mtzApis.startSpinningIcon();
    let updated = await companyService.updateCompanyGroupById(companyGroup.id, editParams);
    mtzApis.stopSpinningIcon();

    if (updated) {
      alert('Group updated');
      setCompanyGroup(updated);
      editFormToggle.current.click();
    }
  };

  if (!companyGroup)
    return <></>

  if (!me || !me.userId || !companyGroup || me.userId !== companyGroup.ownerId)
    return <></>

  return (
    <div>
      <button type="button" ref={editFormToggle}
        className="btn btn-link" data-toggle="modal" data-target="#modal-group-edit">
        Edit group details <span className="fa fa-edit"></span>
      </button>

      <div className="modal" id="modal-group-edit" tabIndex="-1" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Group</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              {
                companyGroup &&
                <form className="d-flex flex-column mtz-gap-8" onSubmit={editGroup}>
                  <div className="form-group">
                    <label>Name:</label>
                    <input onChange={e => setEditParams({ ...editParams, name: e.target.value })}
                      defaultValue={companyGroup.name} className="form-control" />
                  </div>

                  <div className="form-group">
                    <label>Profile Name:</label>
                    <input onChange={e => setEditParams({ ...editParams, profileName: e.target.value })}
                      defaultValue={companyGroup.profileName} className="form-control" />
                  </div>

                  <div className="form-group">
                    <LimitedCharReactQuill value={companyGroup.description}
                      onChange={e => setEditParams({ ...editParams, description: e.target.value })}
                      placeholder="Description: Max 100 Characters*"
                      label={
                        <span>
                          Description:
                        </span>
                      }
                      limit={1000}
                    />
                  </div>

                  <div className="form-check">
                    <input type='checkbox'
                      onChange={e => setEditParams({ ...editParams, asMemberApprovalRequired: editParams.asMemberApprovalRequired === undefined ? !companyGroup.asMemberApprovalRequired : !editParams.asMemberApprovalRequired })}
                      checked={editParams.asMemberApprovalRequired === undefined ? !!companyGroup.asMemberApprovalRequired : !!editParams.asMemberApprovalRequired}
                      className="form-check-input" />
                    <label className="form-check-label">Member auto-approved?:</label>
                  </div>

                  <div className="form-check">
                    <input type='checkbox'
                      onChange={e => setEditParams({ ...editParams, asMemberSelfHideAllowed: editParams.asMemberSelfHideAllowed === undefined ? !companyGroup.asMemberSelfHideAllowed : !editParams.asMemberSelfHideAllowed })}
                      checked={editParams.asMemberSelfHideAllowed === undefined ? !!companyGroup.asMemberSelfHideAllowed : !!editParams.asMemberSelfHideAllowed}
                      className="form-check-input" />
                    <label className="form-check-label">Allow members to hide/show themselves from other members?</label>
                  </div>

                  <div className="form-check">
                    <input type='checkbox'
                      onChange={e => setEditParams({ ...editParams, asMemberHiddenByDefault: editParams.asMemberHiddenByDefault === undefined ? !companyGroup.asMemberHiddenByDefault : !editParams.asMemberHiddenByDefault })}
                      checked={editParams.asMemberHiddenByDefault === undefined ? !!companyGroup.asMemberHiddenByDefault : !!editParams.asMemberHiddenByDefault}
                      className="form-check-input" />
                    <label className="form-check-label">Make new members hidden from other members by default?</label>
                  </div>

                  <div className="text-right">
                    <button className="btn btn-primary ml-auto">Save</button>
                  </div>
                </form>
              }
            </div>
          </div>
        </div>
      </div>

      {
        companyGroup &&
        <>
          <h3 className="mtz-h3">
            Group: <span className="text-primary">{companyGroup.name || ""}</span>
          </h3>

          <div className="mr-3">
            {!companyGroup.asPrivate ? (
              <span>
                <span className="fa fa-unlock"></span> Public
              </span>
            ) : (
              <span>
                <span className="fa fa-lock"></span> Private
              </span>
            )}
          </div>
        </>
      }
    </div>
  );
}
