import React from 'react';

import ParentAuthLayout from '../ParentAuthLayout';
import WithOneColumnLayout from '../WithOneColumnLayout';
import WithThreeColumnLayout from '../WithThreeColumnLayout';
import WithTwoColumnLayout from '../WithTwoColumnLayout';
import MainMenu from './MainMenu';

function UserDashboardLayout2({ component, colSpan }) {
    function NewLayout() {
        return (
            <>
                {!colSpan && 'Missing colSpan attr in UserDashboardLayout2'}
                {colSpan === 1 && <WithOneColumnLayout center={component} />}
                {colSpan === 2 && <WithTwoColumnLayout left={() => (<MainMenu />)} center={component} />}
                {colSpan === 3 && <WithThreeColumnLayout left={() => (<MainMenu />)} center={component} />}
            </>

        );
    }

    return (
        <ParentAuthLayout component={NewLayout} />
    );

}

export default UserDashboardLayout2;

