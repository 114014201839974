import React from "react";
import AuthContext from "../../../../AuthContext";
import mtzApis from "../../../../services";
import * as authUtil from "../../../../services/authUtil";
import LoginForm from "../LoginForm";
import RegisterForm from "../RegisterForm";

const { companyService } = mtzApis;

function OneClickLoginSignUp() {
    const me = React.useContext(AuthContext);
    let [showRegister, setShowRegister] = React.useState(false);
    let [createdUser, setCreatedUser] = React.useState();
    let [code, setCode] = React.useState();

    const verify = async (e) => {
        e.preventDefault();

        if (!code || code.length !== 6) {
            alert("Please enter 6 digits code");
            return;
        }

        mtzApis.startSpinningIcon();
        let req = await companyService.createAuthReq({
            type: 'CONFIRM_EMAIL_WITH_CODE',
            data: {
                email: createdUser.email,
                confirmCode: code
            }
        });
        mtzApis.stopSpinningIcon();

        if (req) {
            saveLoginToken(req);
        }
    }

    const saveLoginToken = async (req) => {
        if (!req.accessToken)
            return;
        let parsed = atob(req.accessToken.split('.')[1]);
        parsed = JSON.parse(parsed);
        authUtil.setLogin(parsed.email, { accessToken: req.accessToken, refreshToken: req.refreshToken });

        // test token
        let user = await companyService.getMyUser();
        if (!user) {
            alert('Invalid login');
            authUtil.removeLogin(parsed.email);
        }

        window.location.reload();
    };

    return (
        <>
            {
                !me.userId &&
                <div>
                    {
                        !showRegister && !createdUser &&
                        <>
                            <LoginForm reload={true} />

                            <hr />

                            <div className="text-center">
                                <button className="btn btn-warning" onClick={() => setShowRegister(true)}>No account? register here</button>
                            </div>
                        </>
                    }

                    {
                        showRegister && !createdUser &&
                        <>
                            <button onClick={() => setShowRegister(false)} className="btn btn-secondary">back</button>
                            <RegisterForm onCreate={newUser => {
                                setCreatedUser(newUser);
                                setShowRegister(false);
                            }} confirmType={'CONFIRM_CODE'} />
                        </>
                    }

                    {
                        createdUser &&
                        <div className="mtz-card">
                            <h5 className="text-success text-center">Congratulation, welcome to MyTradeZone</h5>
                            <div>
                                Step 1: Please check your email '{createdUser.email}' to get 6 digits code.
                            </div>
                            <div>
                                Step 2: Use the code to verify your email below.
                            </div>

                            <form onSubmit={verify}>
                                <div className="form-group">
                                    <label>Enter 6 digits code:</label>
                                    <input type="text" className="form-control" placeholder="6 digits code" maxLength={6}
                                        value={code || ''} onChange={e => {
                                            let val = e.target.value;
                                            val = val.replace(/[^0-9]/g, '');
                                            if (val.length > 6) {
                                                val = val.substring(0, 6);
                                            }
                                            setCode(val);
                                        }}
                                    />
                                </div>
                                <div className="form-group text-right">
                                    <button type="submit" className="btn btn-primary">Verify</button>
                                </div>
                            </form>
                        </div>
                    }
                </div>
            }
        </>
    );
}

export default OneClickLoginSignUp;