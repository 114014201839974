import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import AuthContext from "../../../AuthContext";
import CompanyDetailCard from "../../../components/company-service/companies2/CompanyDetailCard";
import ProductMenuDisplayManager from "../../../components/productMenus/ProductMenuDisplayManager";
import MtzPagination from "../../../components/shared2/MtzPagination";
import WithThreeColumnLayout from "../../../layouts/WithThreeColumnLayout";
import mtzApis from "../../../services";
import LogoBanner from "../v2/CompanyDetailPage/LogoBanner";

const { companyService } = mtzApis;

function CompanyProductMenusPage(props) {
  let [companyId, setCompanyId] = React.useState(props.match.params.companyId);
  let [profileName, setProfileName] = React.useState(props.match.params.profileName);
  const me = React.useContext(AuthContext);
  let [company, setCompany] = React.useState(null);
  let [menu, setMenu] = React.useState(null);
  let [pSearches, setPSearches] = React.useState(null);
  let [pSearchStats, setPSearchStats] = React.useState(null);
  let [selectedProducts, setSelectedProducts] = React.useState([]);
  let [searchProducts, setSearchProducts] = React.useState([]);
  let [searchProductStats, setSearchProductStats] = React.useState(null);
  let [keyword, setKeyword] = React.useState("");
  let modalAddProductToMenuRef = React.useRef();

  React.useEffect(() => {
    (async () => {
      let companyId = props.match.params.companyId;
      setCompanyId(companyId);

      let profileName = props.match.params.profileName;
      setProfileName(profileName);

      let companies = await companyService.getCompanies(
        new URLSearchParams(`profileName=${profileName}`)
      );
      if (companies && companies.length > 0) setCompany(companies[0]);

      // load selected menu
      let productMenuId = new URLSearchParams(props.location.search).get("productMenuId");
      if (!productMenuId) return;
      menu = await companyService.getProductMenuById(productMenuId);
      if (menu) {
        setMenu(menu);
        loadMenuItems();

        let pSearchStats = await companyService.getProductMenuItemStats(new URLSearchParams(`productMenuIds=${menu.id}`));
        if (pSearchStats) setPSearchStats(pSearchStats);
      }
    })();
  }, [props.match.params]);

  const loadMenuItems = async (skip = 0, limit = 16) => {
    let menuItems = await companyService.getProductMenuItems(new URLSearchParams(`productMenuIds=${menu.id}&skip=${skip}&limit=${limit}`));
    if (menuItems && menuItems.length > 0) {
      let productIds = menuItems.map((item) => item.productId);
      pSearches = await companyService.getProductSearches(new URLSearchParams(`productIds=${productIds}`));

      // load product images
      let reqs = pSearches.map(pSearch => companyService.getProductImages(new URLSearchParams(`productIds=${pSearch.product.id}&limit=16`)));
      let images = await Promise.all(reqs);
      let map = {};
      images.forEach(imgs => imgs.length > 0 && (map[imgs[0].productId] = imgs[0].path));
      pSearches.forEach(pSearch => pSearch.product.image = map[pSearch.product.id]);
      if (pSearches) setPSearches(pSearches);
    }
  };

  const removeProductFromMenu = async (search) => {
    const ok = await window.createMtzConfirm(`Are you sure?`);
    if (!ok) return;

    let items = await companyService.getProductMenuItems(new URLSearchParams(`productMenuIds=${menu.id}&productIds=${search.product.id}`));
    if (!items || items.length === 0) return;
    mtzApis.startSpinningIcon();
    let deleted = await companyService.deleteProductMenuItemById(items[0].id);
    mtzApis.stopSpinningIcon();

    if (deleted) {
      pSearches = pSearches.filter((p) => p.id !== search.id);
      setPSearches(pSearches);
      pSearchStats.all.count--;
      setPSearchStats(pSearchStats);
      alert("Product removed from the menu successfully");
    }
  };

  const addProdsToMenu = async () => {
    if (selectedProducts.length === 0) {
      alert("Please select products to add");
      return;
    }

    mtzApis.startSpinningIcon();
    let reqs = selectedProducts.map((p) => companyService.createProductMenuItem({ productMenuId: menu.id, productId: p.id }));
    let createds = await Promise.all(reqs);
    mtzApis.stopSpinningIcon();

    if (createds && createds.filter(c => !!c).length > 0) {
      setSelectedProducts([]);
      modalAddProductToMenuRef.current.click();
      loadMenuItems();
    }
  };

  const findProducts = async (k, skip, limit) => {
    keyword = k;
    setKeyword(keyword);

    searchProducts = await companyService.getProductSearches(
      new URLSearchParams(
        `keyword=${keyword}&companyIds=${company.id}&limit=${searchProducts.length + 16}`
      )
    );
    if (searchProducts) setSearchProducts(searchProducts);

    searchProductStats = await companyService.getProductSearchStats(
      new URLSearchParams(`keyword=${keyword}&companyIds=${company.id}`)
    );
    if (searchProductStats) setSearchProductStats(searchProductStats);
  };

  if (!me || JSON.stringify(me) === '{}') {
    return (
      <span>
        Login required
      </span>
    );
  } else {
    if (!company && company != null)
      return (
        <span>
          You don't have any company, If you need to setup your company, click&nbsp;
          <NavLink to="/me/company">HERE</NavLink>.
        </span>
      );
    if (company == null)
      return (
        <span>
          Loading...
        </span>
      );
  }

  return (
    <div className="d-flex flex-column w-100" style={{ gap: "20px" }}>
      <LogoBanner companyId={companyId} profileName={profileName} />

      <ProductMenuDisplayManager
        profileName={profileName}
        companyId={companyId}
      />

      {/* selected menu */}
      {
        menu && (
          <div className="mtz-card">
            <h5 className="text-center mtz-h5 my-4">Products/services of the menu <span className="text-info">{`"${menu.name}"`}</span></h5>

            {
              me && me.userId === company.ownerId &&
              <div>
                <button className="btn btn-outline-primary my-2" data-toggle="modal" data-target="#modal-add-prod-to-menu" ref={modalAddProductToMenuRef}>
                  <i className="fa fa-plus"></i> Add product(s) to menu
                </button>

                <div className="modal fade show" id="modal-add-prod-to-menu" tabIndex="-1" role="dialog" >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Add products to the menu '{menu && menu.name}'</h5>
                        <button
                          type="button"
                          className="close"
                          onClick={() => modalAddProductToMenuRef.current.click()}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>

                      <div className="modal-body">
                        {
                          menu &&
                          <>
                            <div className="d-flex align-items-center mtz-gap-8">
                              {
                                selectedProducts && selectedProducts.length > 0 &&
                                <>
                                  <b>
                                    <u>Selected products:</u>
                                  </b>
                                  &nbsp;
                                  {
                                    selectedProducts &&
                                    selectedProducts.map(p =>
                                      <u className="d-flex align-items-center" key={p.id}>
                                        {p.name} <i className="fa fa-trash mtz-cursor-pointer text-danger" onClick={() =>
                                          setSelectedProducts(
                                            selectedProducts.filter((selected) => selected.id !== p.id)
                                          )}></i>
                                      </u>
                                    )
                                  }
                                  <button className="btn btn-outline-primary btn-sm" onClick={addProdsToMenu}>
                                    Save
                                  </button>
                                </>
                              }
                            </div>

                            <div className="form-group">
                              <label htmlFor="search">Search products</label>
                              <input className="form-control" type="text" placeholder="Search products" onChange={(e) => findProducts(e.target.value)} />
                            </div>

                            {
                              searchProducts &&
                              searchProducts.length > 0 &&
                              <div className="d-flex flex-column mtz-gap-16">
                                {
                                  searchProducts.map((p) => (
                                    <div
                                      key={p.id}
                                      className="border mtz-p-8 mtz-rounded-8 mtz-cursor-pointer"
                                      onClick={() =>
                                        setSelectedProducts([...new Set([...selectedProducts, p.product])]
                                        )
                                      }
                                    >
                                      {p.product.name} - SKU: {p.product.sku}
                                    </div>
                                  ))
                                }
                              </div>
                            }

                            {/* <div>
              <MtzPagination defaultPerPage={1} total={searchProductStats.all.count} onChange={load}
                </div> */}
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

            {!pSearches && "Loading..."}

            {pSearches && pSearches.length > 0 ? (
              <div className="container">
                <div className="row d-flex align-items-stretch">
                  {pSearches.map((pSearch) => (
                    <div key={pSearch.id} className="col-12 col-sm-6 col-md-4 my-2">
                      <div className="shadow mtz-card h-100">
                        <div className="flex-grow-1">
                          <NavLink
                            target="_blank"
                            to={`/products/${company.profileName}/${pSearch.product.sku}`}
                          >
                            <div>
                              <b>{pSearch.product.service ? 'Service' : 'Product'}:</b> {pSearch.product.name}
                            </div>
                          </NavLink>

                          <div>
                            <small><b>SKU:</b> {pSearch.product.sku}</small>
                          </div>

                          <div className="text-right">
                            <small><b>Date listed:</b> {new Date(pSearch.product.createdAt).toLocaleDateString()}</small>
                          </div>
                        </div>

                        <div className="text-center">
                          <img
                            style={{ maxHeight: "130px" }}
                            className="rounded mw-100 mx-auto"
                            src={pSearch.product.image ? (companyService.getBackendHost() + pSearch.product.image) : "/assets/images/empty.png"}
                          />
                        </div>

                        <div className="text-right">
                          {menu.companyId === company.id && me && me.userId === company.ownerId && (
                            <button className="btn btn-outline-danger btn-sm my-2" onClick={() => removeProductFromMenu(pSearch)}>
                              Remove from menu
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {
                  pSearchStats &&
                  <MtzPagination total={pSearchStats.all.count}
                    onChange={loadMenuItems} defaultPerPage={16} />
                }

              </div>
            ) : (
              <span>This menu has no product</span>
            )}
          </div>
        )
      }
    </div >
  );
}

function Left(props) {
  let [companyId, setCompanyId] = React.useState(props.match.params.companyId);
  let [profileName, setProfileName] = React.useState(
    props.match.params.profileName
  );

  React.useEffect(() => {
    (async () => {
      let companyId = props.match.params.companyId;
      setCompanyId(companyId);

      let profileName = props.match.params.profileName;
      setProfileName(profileName);
    })();
  }, [props.match.params]);

  return (
    <div className="w-100">
      <CompanyDetailCard companyId={companyId} profileName={profileName} />
    </div>
  );
}

export default () => (
  <WithThreeColumnLayout
    left={withRouter(Left)}
    center={withRouter(CompanyProductMenusPage)}
  />
);
