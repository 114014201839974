import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import mtzApis from "../../../../services";
import sessionStorageUtil from "../../../../utils/sessionStorageUtil";

const { companyService } = mtzApis;

class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      firstname: "",
      lastname: "",
      email: "",
      passwordType: "password",
      passwordValidationErrors: ["min", "uppercase", "digits", "letters"],
      password: "",
      confirm_password: "",
      companyName: "",
      hasCompany: true,
      extraQuestion: null,
      selectedBusinessType: "",
      selectedBusinessTypes: [],
      isOpenDropdown: false,
    };
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    window.document.addEventListener("mousedown", (event) => {
      if (this.dropdownRef.current && this.dropdownRef.current.contains(event.target)) {
        this.setState({ isOpenDropdown: true });
      } else {
        this.setState({ isOpenDropdown: false });
      }
    });

    this.init();
  }

  componentWillUnmount() {
    window.document.removeEventListener("mousedown", (event) => {
      if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
        this.dropdownRef.current.classList.remove("show");
      }
    });
  }

  init = async () => {
    let extraQuestions = await companyService.getUserExtraQuestions(
      new URLSearchParams(`keyword=offers`)
    );


    if (extraQuestions && extraQuestions.length > 0) {
      let businessTypeQuestion = extraQuestions[0];
      let questionId = businessTypeQuestion.id;
      let choices = await companyService.getUserExtraQuestionChoices(
        new URLSearchParams(`questionIds=${questionId}`)
      );

      businessTypeQuestion.choices = choices;

      this.setState({ extraQuestion: businessTypeQuestion });
    }
  };

  handlePasswordVisibility = () =>
    this.setState(({ passwordType }) => ({
      passwordType: passwordType === "text" ? "password" : "text",
    }));

  passwordValidate = (password) => {
    let errors = this.findErrors(password);
    if (errors.length === 0) {
      this.setState({ password: password });
    }
    this.setState({ passwordValidationErrors: errors, password: password });
  };

  confirmPasswordValidate = (confirm_password) => {
    this.validate(confirm_password);
    this.setState({ confirm_password: confirm_password });
  };

  validate(confirm_password) {
    let { password } = this.state;
    let errors = {};
    let isValid = true;
    if (password !== "" && confirm_password !== "") {
      if (password !== confirm_password) {
        isValid = false;
        errors["confirm_password"] = "Password does not match!";
      } else {
        errors["confirm_password"] = "";
      }
    } else {
      errors["confirm_password"] = "Enter Password and Confirm Password!";
      isValid = false;
    }
    this.setState({
      errors: errors,
    });
    return isValid;
  }

  findErrors(password) {
    let errors = [];
    let uppercase = /[A-Z]/g;
    let specialCharacter = /[^a-zA-Z\d]/g;
    let digit = /[0-9]/g;
    let letter = /[a-zA-Z]/g;

    if (password.length < 8) errors.push("min");
    else {
      let index = errors.indexOf("min");
      if (index !== -1) errors.splice(index, 1);
    }

    if (!uppercase.test(password)) errors.push("uppercase");
    else {
      let index = errors.indexOf("uppercase");
      if (index !== -1) errors.splice(index, 1);
    }

    if (!digit.test(password)) errors.push("digits");
    else {
      let index = errors.indexOf("digits");
      if (index !== -1) errors.splice(index, 1);
    }

    if (!letter.test(password)) errors.push("letters");
    else {
      let index = errors.indexOf("letters");
      if (index !== -1) errors.splice(index, 1);
    }

    return errors;
  }

  createUserHandler = async (event) => {
    mtzApis.stopSpinningIcon();

    try {
      event.preventDefault();
      let {
        email,
        password,
        confirm_password,
        passwordValidationErrors,
        firstname,
        lastname,
        companyName,
        extraQuestion,
        selectedBusinessTypes,
      } = this.state;

      if (this.state.hasCompany && companyName.length === 0) {
        alert("Company Name is required!!");
        return;
      }

      if (
        selectedBusinessTypes.length === 0 ||
        !selectedBusinessTypes.every((type) =>
          extraQuestion.choices.some((choice) => choice.id === type)
        )
      ) {
        alert("Please select at least one valid business type.");
        return;
      }

      if (this.validate(confirm_password) && passwordValidationErrors.length === 0) {
        let user = {};
        user["firstname"] = firstname;
        user["lastname"] = lastname;
        user["password"] = password;
        user["email"] = email;

        // Check session storage for tracking code
        const referralGroupTcId = sessionStorageUtil.get(
          sessionStorageUtil.COMPANY_SERVICE,
          sessionStorageUtil.TRACKING_CODES,
          'referal_group_tc'
        );

        if (referralGroupTcId) {
          const tc = await companyService.getTrackingCodeById(referralGroupTcId);
          // Check if companyGroupIds exists in tracking code metadata
          if (tc && tc.meta && tc.meta.companyGroupIds) {
            user.meta = { referralCompanyGroupIds: tc.meta.companyGroupIds };
          }
        }

        let confirmType = this.props.confirmType;
        user.confirmType = confirmType;

        mtzApis.startSpinningIcon();
        let createdUser = await companyService.createUser(user);
        if (createdUser) {
          let params = new URLSearchParams(window.location.search);
          let coupon = params.get("coupon");
          let tc = params.get("tc")

          if (!!coupon) {
            let res = await companyService.createCouponUse({ userId: createdUser.id, email: createdUser.email, code: coupon });
          }
          if (this.state.hasCompany) {
            let a = await companyService.createCompanyName({
              userId: createdUser.id,
              companyName: companyName,
            });
          }

          let answers = selectedBusinessTypes.map((choiceId) => ({
            choiceId,
            questionId: extraQuestion.id,
            userId: createdUser.id,
          }));

          await Promise.all(
            answers.map((answer) => companyService.createUserExtraQuestionAnswer(answer))
          );

          if (this.props.onCreate) {
            this.props.onCreate(createdUser);
            return;
          }

          this.props.history.push("/register-success");
        }
      } else alert("Check password requirements!!");
    } catch (error) {
      console.log(error);
    } finally {
      mtzApis.stopSpinningIcon();
    }
  };

  resetForm = () => {
    this.setState({
      errors: {},
      firstname: null,
      lastname: null,
      email: null,
      password: null,
      confirm_password: null,
      showPassword: false,
    });
  };
  render() {
    let { passwordType, errors, passwordValidationErrors, extraQuestion, selectedBusinessTypes } =
      this.state;
    return (
      <div className="mtz-card">
        <h5 className="mtz-h4 text-primary text-center">Welcome to the social network for businesses</h5>

        <form
          className="d-flex w-100 flex-column mtz-gap-8"
          onSubmit={(event) => this.createUserHandler(event)}
        >
          <div className="form-group">
            <label>First Name:</label>
            <input
              className="form-control"
              required
              onChange={(event) => this.setState({ firstname: event.target.value })}
              value={this.state.firstname || ""}
              placeholder="First Name"
            />
          </div>

          <div className="form-group">
            <label>Last Name:</label>
            <input
              className="form-control"
              required
              onChange={(event) => this.setState({ lastname: event.target.value })}
              value={this.state.lastname || ""}
              placeholder="Last Name"
            />
          </div>

          <div className="form-group">
            <label>Email:</label>
            <input
              className="form-control"
              required
              onChange={(event) => this.setState({ email: event.target.value })}
              value={this.state.email || ""}
              placeholder="Business Email"
            />
          </div>

          <div className="form-group">
            <label>Password:</label>
            <input className="form-control"
              required
              type={passwordType}
              value={this.state.password || ""}
              placeholder="Password"
              onChange={(event) => this.passwordValidate(event.target.value)}
            />
          </div>

          <div className="form-group">
            <label>Confirm Password:</label>
            <input className="form-control"
              required
              onChange={(event) => this.confirmPasswordValidate(event.target.value)}
              value={this.state.confirm_password || ""}
              type={passwordType}
              placeholder="Confirm Password"
            />
            <div className="text-danger">{errors.confirm_password}</div>
          </div>

          <div className="alert alert-danger">
            {passwordValidationErrors.includes("min") === true ? (
              <small className="font-weight-bold">** Minimum length-8</small>
            ) : (
              <small className="text-success">✔ Minimum length-8</small>
            )}
            <br />
            {passwordValidationErrors.includes("uppercase") === true ? (
              <small className="font-weight-bold">** Uppercase-1</small>
            ) : (
              <small className="text-success">✔ Uppercase-1</small>
            )}
            <br />
            {passwordValidationErrors.includes("letters") === true ? (
              <small className="font-weight-bold">** Letter-1</small>
            ) : (
              <small className="text-success">✔ Letter-1</small>
            )}
            <br />
            {passwordValidationErrors.includes("digits") === true ? (
              <small className="font-weight-bold">** Number-1</small>
            ) : (
              <small className="text-success">✔ Number-1</small>
            )}
          </div>

          <hr />

          <div className="form-group">
            <div className="form-check">
              <label
                className="form-check-label"
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  checked={!this.state.hasCompany}
                  onChange={(event) => this.setState({ hasCompany: !event.target.checked })}
                />

                Sign up as a business professional, without a company.
              </label>
            </div>

            {this.state.hasCompany && (
              <div className="w-100">
                <input
                  className="form-control"
                  required
                  onChange={(event) => this.setState({ companyName: event.target.value })}
                  value={this.state.companyName || ""}
                  placeholder="Company Name"
                />
              </div>
            )}
          </div>

          <div className="form-group">
            <label>
              Business Type:
            </label>
            {
              extraQuestion && (
                <div className="form-group">
                  <div className={`dropdown`}
                    ref={this.dropdownRef}>
                    <button
                      className="dropdown- btn btn-outline-secondary"
                      type="button"
                      data-toggle="dropdown"
                    >
                      Select up to 5 categories that match you or your business
                    </button>

                    <div className={`dropdown-menu w-100 ${this.state.isOpenDropdown ? "show" : ""}`}
                      style={{ maxHeight: "200px", overflowY: "auto" }}>
                      {extraQuestion.choices.map((choice) => (
                        <div className="dropdown-item w-100 form-check" key={choice.id} >
                          <label className="form-check-label w-100">
                            <input className="form-check-input"
                              type="checkbox"
                              onChange={(e) => {
                                const selectedBusinessTypes = [...this.state.selectedBusinessTypes];
                                const selectedCategoryId = choice.id;

                                if (e.target.checked) {
                                  // Check if the limit has been reached
                                  if (selectedBusinessTypes.length >= 5) {
                                    alert("You can only add 5 categories.");
                                    return; // Stop further execution
                                  }
                                  // Add the category if the limit has not been reached
                                  selectedBusinessTypes.push(selectedCategoryId);
                                } else {
                                  // Remove the category if unchecked
                                  const index = selectedBusinessTypes.indexOf(selectedCategoryId);
                                  if (index !== -1) {
                                    selectedBusinessTypes.splice(index, 1);
                                  }
                                }
                                // Update the state
                                this.setState({ selectedBusinessTypes });
                              }}
                              checked={selectedBusinessTypes.includes(choice.id)}
                            />
                            {choice.choice}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )
            }
          </div>

          <div className="alert alert-secondary text-center">
            <p className="txt-367">
              By clicking “Register”, you agree to our{" "}
              <a href="/terms" target="_blank" className="txt-3672">
                terms of service
              </a>{" "}
              and{" "}
              <a href="privacy" target="_blank" className="txt-3672">
                privacy policy
              </a>
            </p>
          </div>

          <button
            className="btn btn-primary"
            type='submit'
          >
            Register
          </button>
        </form>
      </div>
    );
  }
}

export default withRouter(RegisterForm);
