import * as authUtil from "../authUtil";
import {
  ALERT_SERVER_URL
} from "../config";

import SockJS from 'sockjs-client';
import Stomp from 'stompjs';

const SERVER_URL = ALERT_SERVER_URL;

function MtzWebsocket(onMessage, onWsConn) {
  const newNotificationCallbacks = {};
  let reConnectCount = 0;
  let stompClient = null;
  let socket = null;
  let uiid = new Date().getTime();

  const connect = async () => {
    const tokens = authUtil.getLogins();
    let token = (tokens && tokens.length > 0) ? tokens[0] : {};
    token = Object.values(token)[0] || '';

    socket = new SockJS(`${SERVER_URL}/ws`);
    stompClient = Stomp.over(socket);
    stompClient.debug = null;
    stompClient.connect({
      "Authorization": "Bearer " + token,
      "uiid": uiid,
    }, onConnected, onError);
  }
  connect();

  this.disconnect = function () {
    stompClient.disconnect();
  }

  async function onConnected() {
    console.log("WS connected");

    if (onWsConn)
      onWsConn(uiid);

    reConnectCount = 0;
    stompClient.subscribe('/user/topic/mtz', function (message) {
      message = JSON.parse(message.body);
      onMessage(message);
    });
  }

  async function onError(error) {
    console.log(error);

    if (reConnectCount < 3) {
      console.log(`Reconnecting WS attemp ${reConnectCount}...`);
      await new Promise(r => setTimeout(r, 4000));
      await connect();
      reConnectCount++;
    } else {
      console.log("WS conn err...");
    }
  }

  this.getSessionId = function () {
    return uiid;
  };
}

export default MtzWebsocket;