import React from 'react';
import EventLayout from './layouts/EventLayout';
import GroupLayout from './layouts/GroupLayout';
import ParentLayout from './layouts/ParentLayout';
import UserDashboardLayout2 from './layouts/UserDashboardLayout2';
import CompanyProductMenusPage from './pages/public/CompanyProductMenusPage';

import MyCouponPage from './pages/user/MyCouponPage';
import MyCRMPage from './pages/user/MyCRMPage';
import * as ROUTES from './utils/constants/routes';

// layouts
let AdminLayout = React.lazy(() => import("./layouts/AdminLayout"));
let GranteeLayout = React.lazy(() => import("./layouts/GranteeLayout"));
let ParentAuthLayout = React.lazy(() => import("./layouts/ParentAuthLayout"));
let ChatLayout2 = React.lazy(() => import("./layouts/ChatLayout2"));

// public pages
let EventDirectoryPage = React.lazy(() => import('./pages/public/EventDirectoryPage'));
let GroupDirectoryPage = React.lazy(() => import('./pages/public/GroupDirectoryPage'));
let ProductDirectoryPage = React.lazy(() => import('./pages/public/ProductDirectoryPage'));
let CompanyDirectoryPage = React.lazy(() => import('./pages/public/CompanyDirectoryPage'));
let HomePage2 = React.lazy(() => import('./pages/public/HomePage2'));
let AboutUs = React.lazy(() => import('./pages/public/AboutUs'));
let MtzFileSharePage = React.lazy(() => import('./pages/public/MtzFileSharePage'));
let ContactUsPage = React.lazy(() => import('./pages/public/ContactUsPage'));
let SiteDirectoryPage = React.lazy(() => import('./pages/public/SiteDirectoryPage'));
let MtzNewsPage = React.lazy(() => import('./pages/public/MtzNewsPage'));
let PrivacyPolicy = React.lazy(() => import('./pages/public/PrivacyPolicy'));
let CompanyFeedPage = React.lazy(() => import("./pages/public/CompanyFeedPage"));
let FormBuilderFillingPage = React.lazy(() => import("./pages/public/FormBuilderFillingPage"));
let SearchPage = React.lazy(() => import("./pages/public/SearchPage"));
let TermsAndUse = React.lazy(() => import("./pages/public/TermsAndUse"));
let PlansAndPricingPage = React.lazy(() => import("./pages/public/PlansAndPricingPage"));
let MtzPlanSubChangerPage = React.lazy(() => import("./pages/user/MtzPlanSubChangerPage"));
let MtzSubscriptionCheckoutPage = React.lazy(() => import("./pages/public/MtzSubscriptionCheckoutPage"));
let MtzSubscriptionPaymentPage = React.lazy(() => import("./pages/public/MtzSubscriptionPaymentPage"));
let MtzSubscriptionSuccessPage = React.lazy(() => import("./pages/public/MtzSubscriptionSuccessPage"));
let SponsorshipSubscriptionCheckoutPage = React.lazy(() => import("./pages/public/SponsorshipSubscriptionCheckoutPage"));
let ComingSoonPage = React.lazy(() => import("./pages/public/ComingSoonPage"));
let LogoutPage = React.lazy(() => import('./pages/public/LogoutPage'));
let RegisterConfirmPage = React.lazy(() => import('./pages/public/RegisterConfirmPage'));
let RegisterConfirmResendPage = React.lazy(() => import('./pages/public/RegisterConfirmResendPage'));
let RegisterPage = React.lazy(() => import('./pages/public/RegisterPage'));
let ResetConfirmPage = React.lazy(() => import('./pages/public/ResetConfirmPage'));
let ResetPage = React.lazy(() => import('./pages/public/ResetPage'));
let CompanyGroupDetailPage = React.lazy(() => import('./pages/public/CompanyGroupDetailPage'));
let CompanyGroupCategoryPage = React.lazy(() => import('./pages/public/CompanyGroupCategoryPage'));
let FeedDetailPage = React.lazy(() => import('./pages/public/FeedDetailPage'));
let CompanyGroupPostDetailPage = React.lazy(() => import('./pages/public/CompanyGroupPostDetailPage'));
let RfqDetailPage = React.lazy(() => import("./pages/public/RfqDetailPage"));
let HashtagDetailPage = React.lazy(() => import("./pages/public/HashtagDetailPage"));
let FaqDetailPage = React.lazy(() => import("./pages/public/FaqDetailPage"));
let FaqPage = React.lazy(() => import("./pages/public/FaqPage"));
let CompanyGroupPostPage = React.lazy(() => import('./pages/public/CompanyGroupPostPage'));
let CompanyGroupCategoryMembersPage = React.lazy(() => import('./pages/public/CompanyGroupCategoryMembersPage'));
let CompanyGroupResourcePage = React.lazy(() => import('./pages/public/CompanyGroupResourcePage'));
let CompanyGroupRoomPage = React.lazy(() => import('./pages/public/CompanyGroupRoomPage'));
let CompanyGroupMembersPage = React.lazy(() => import('./pages/public/CompanyGroupMembersPage'));
let CGMemberInvitePage = React.lazy(() => import('./pages/public/CGMemberInvitePage'));
let CGMemberInviteDetailPage = React.lazy(() => import('./pages/public/CGMemberInviteDetailPage'));
let CGEventPage = React.lazy(() => import('./pages/public/CGEventPage'));
let CGEventTicketOrderDetailPage = React.lazy(() => import('./pages/public/CGEventTicketOrderDetailPage'));
let CompanyGroupSponsorPage = React.lazy(() => import('./pages/public/CompanyGroupSponsorPage'));
let CGEventDetailPage = React.lazy(() => import('./pages/public/CGEventDetailPage'));
let CGEventSponsorPage = React.lazy(() => import('./pages/public/CGEventSponsorPage'));
let CompanyGroupSponsorSubscribeSuccessPage = React.lazy(() => import('./pages/public/CompanyGroupSponsorSubscribeSuccessPage'));
let CompanyGroupSponsorAdPage = React.lazy(() => import('./pages/public/CompanyGroupSponsorAdPage'));
let CGOwnerStatsPage = React.lazy(() => import('./pages/cgOwner/CGOwnerStatsPage'));
let CGSponsorClickStatPage = React.lazy(() => import('./pages/cgOwner/CGSponsorClickStatPage'));
let CGMemberClickStatPage = React.lazy(() => import('./pages/cgOwner/CGMemberClickStatPage'));
let RegisterSuccessPage = React.lazy(() => import('./pages/public/RegisterSuccessPage'));

// user pages
let MyCompanyAddPage = React.lazy(() => import('./pages/user/MyCompanyAddPage'));
let MyMtzFilePage = React.lazy(() => import('./pages/user/MyMtzFilePage'));
let MyMtzFileRequestPage = React.lazy(() => import('./pages/user/MyMtzFileRequestPage'));
let MyTagAlertPage = React.lazy(() => import('./pages/user/MyTagAlertPage'));
let MyCompanyExtraInfoPage = React.lazy(() => import('./pages/user/MyCompanyExtraInfoPage'));
let GetStartedPage = React.lazy(() => import('./pages/user/GetStartedPage'));
let MyCompanyViewStatsPage = React.lazy(() => import('./pages/user/MyCompanyViewStatsPage'));
let MyCompanyWatchlistDetailPage = React.lazy(() => import('./pages/user/MyCompanyWatchlistDetailPage'));
let MyCompanyWatchlistsPage = React.lazy(() => import('./pages/user/MyCompanyWatchlistsPage'));
let MyCompanyNotesPage = React.lazy(() => import('./pages/user/MyCompanyNotesPage'));
let MyConnectionsPage = React.lazy(() => import('./pages/user/MyConnectionsPage'));
let MyFollowingsPage = React.lazy(() => import('./pages/user/MyFollowingsPage'));
let MyProductAddPage = React.lazy(() => import('./pages/user/MyProductAddPage'));
let MyProductMenusPage = React.lazy(() => import('./pages/user/MyProductMenusPage'));
let MyProductUploadPage = React.lazy(() => import('./pages/user/MyProductUploadPage'));
let MyProductsPage = React.lazy(() => import('./pages/user/MyProductsPage'));
let MyProductViewStatsPage = React.lazy(() => import('./pages/user/MyProductViewStatsPage'));
let MyTrackingCodeLogsPage = React.lazy(() => import('./pages/user/MyTrackingCodeLogsPage'));
let MyReferralTrackingCodeRewardPage = React.lazy(() => import('./pages/user/MyReferralTrackingCodeRewardPage'));
let MyTrackingCodesPage = React.lazy(() => import('./pages/user/MyTrackingCodesPage'));
let MyDashboardPage = React.lazy(() => import('./pages/user/MyDashboardPage'));
let UserProfilePage = React.lazy(() => import('./pages/user/UserProfilePage'));
let MyAccountPage = React.lazy(() => import('./pages/user/MyAccountPage'));
let MyAccountRemovalPage = React.lazy(() => import('./pages/user/MyAccountRemovalPage'));
let MyContactGroupsPage = React.lazy(() => import('./pages/user/MyContactGroupsPage'));
let MyContactGroupMemberPage = React.lazy(() => import('./pages/user/MyContactGroupMemberPage'));
let MyContactsPage = React.lazy(() => import('./pages/user/MyContactsPage'));
let MyContactUploadEventPage = React.lazy(() => import('./pages/user/MyContactUploadEventPage'));
let MyFormBuilderFeedbacksPage = React.lazy(() => import('./pages/user/MyFormBuilderFeedbacksPage'));
let MyFormBuildersPage = React.lazy(() => import('./pages/user/MyFormBuildersPage'));
let MyEmailCampaignPage = React.lazy(() => import('./pages/user/MyEmailCampaignPage'));
let MyEmailLayoutPage = React.lazy(() => import('./pages/user/MyEmailLayoutPage'));
let MyRfisPage = React.lazy(() => import('./pages/user/MyRfisPage'));
let MyCompanyGroupsPage = React.lazy(() => import('./pages/user/MyCompanyGroupsPage'));
let MyCompanyGroupInvitesPage = React.lazy(() => import('./pages/user/MyCompanyGroupInvitesPage'));
let MyCompanyGroupRequestsPage = React.lazy(() => import('./pages/user/MyCompanyGroupRequestsPage'));
let MyCompanyGroupSponsorInvitesPage = React.lazy(() => import('./pages/user/MyCompanyGroupSponsorInvitesPage'));
let UserTimelinePage = React.lazy(() => import('./pages/user/UserTimelinePage'));
let MyRfqAddPage = React.lazy(() => import("./pages/user/MyRfqAddPage"));
let MyRfqPage = React.lazy(() => import("./pages/user/MyRfqPage"));
let MyRfqViewStatsPage = React.lazy(() => import("./pages/user/MyRfqViewStatsPage"));
let NewAdminDashboardPage = React.lazy(() => import("./pages/admin/NewAdminDashboardPage"));
let MyPaymentSuccessPage = React.lazy(() => import("./pages/user/MyPaymentSuccessPage"));
let MyProductWatchlistPage = React.lazy(() => import('./pages/user/MyProductWatchlistsPage'));
let MyCompanyGroupAddPage = React.lazy(() => import('./pages/user/MyCompanyGroupAddPage'));
let MyBillingPage = React.lazy(() => import('./pages/user/MyBillingPage'));
let MyWalletPage = React.lazy(() => import('./pages/user/MyWalletPage'))
let MyPointBalancePage = React.lazy(() => import('./pages/user/MyPointBalancePage'))
let MyDonationPage = React.lazy(() => import('./pages/user/MyDonationPage'));
let MySubscriptionPage = React.lazy(() => import('./pages/user/MySubscriptionPage'));
let MyMtzSellerPage = React.lazy(() => import('./pages/user/MyMtzSellerPage'));
let MyPaymentAccountPage = React.lazy(() => import('./pages/user/MyPaymentAccountPage'));
// let MyGlobalCalendarPage = React.lazy(() => import('./pages/user/MyGlobalCalendarPage'));
let MyAdvertisementsPage = React.lazy(() => import('./pages/user/MyAdvertisementsPage'));
let MyOrganicListingsPage = React.lazy(() => import('./pages/user/MyOrganicListingsPage'));
let MyEmailCampaignDetailPage = React.lazy(() => import('./pages/user/MyEmailCampaignDetailPage'));
let MyAdTemplatesPage = React.lazy(() => import('./pages/user/MyAdTemplatesPage'));
let MyConnectionQRPage = React.lazy(() => import('./pages/user/MyConnectionQRPage'));
let MyCGAdBannersPage = React.lazy(() => import('./pages/user/MyCGAdBannersPage'));
let MyNotificationPage = React.lazy(() => import('./pages/user/MyNotificationPage'));
let MyAdCampaignsPage = React.lazy(() => import('./pages/user/MyAdCampaignsPage'));
let MyAdCampaignAddPage = React.lazy(() => import('./pages/user/MyAdCampaignAddPage'));
let MyAdClickPage = React.lazy(() => import('./pages/user/MyAdClickPage'));
let MyAdCampaignDetailPage = React.lazy(() => import('./pages/user/MyAdCampaignDetailPage'));
let MyExtraInfoPage = React.lazy(() => import('./pages/user/v2/MyExtraInfoPage'));
let MyCompanyGroupSponsorPage = React.lazy(() => import('./pages/user/MyCompanyGroupSponsorPage'));

// admin pages
let StripeCustomerPage = React.lazy(() => import("./pages/admin/StripeCustomerPage"));
let StripeSubscriptionPage = React.lazy(() => import("./pages/admin/StripeSubscriptionPage"));

let CompanyManagerPage = React.lazy(() => import('./pages/admin/CompanyManagerPage'));
let CompanyExtraQuestionPage = React.lazy(() => import('./pages/admin/CompanyExtraQuestionPage'));
let CompanyExtraQuestionChoicePage = React.lazy(() => import('./pages/admin/CompanyExtraQuestionChoicePage'));
let ProductManagerPage = React.lazy(() => import('./pages/admin/ProductManagerPage'));
let SiteMapPage = React.lazy(() => import('./pages/admin/SiteMapPage'));
let EmailSchemasPage = React.lazy(() => import('./pages/admin/EmailSchemasPage'));
let EmailSchemaDetailPage = React.lazy(() => import('./pages/admin/EmailSchemaDetailPage'));
let UserManagerPage = React.lazy(() => import('./pages/admin/UserManagerPage'));
let EmailCampaignPage = React.lazy(() => import('./pages/admin/EmailCampaignPage'));
let EmailCampaignTriggerPage = React.lazy(() => import('./pages/admin/EmailCampaignTriggerPage'));
let EmailCampaignGroupDetailPage = React.lazy(() => import('./pages/admin/EmailCampaignGroupDetailPage'));
let EmailCampaignScheduledPage = React.lazy(() => import('./pages/admin/EmailCampaignScheduledPage'));
let EmailCampaignOnetimePage = React.lazy(() => import('./pages/admin/EmailCampaignOnetimePage'));
let EmailCampaignDetailPage = React.lazy(() => import('./pages/admin/EmailCampaignDetailPage'));
let EmailCampaignTriggerDetailPage = React.lazy(() => import('./pages/admin/EmailCampaignTriggerDetailPage'));
let SyncPage = React.lazy(() => import('./pages/admin/SyncPage'));
let UserSyncPage = React.lazy(() => import('./pages/admin/UserSyncPage'));
let UserSyncDetailPage = React.lazy(() => import('./pages/admin/UserSyncDetailPage'));
// let CronJobPage = React.lazy(() => import('./pages/admin/CronJobPage'));
let PermissionPage = React.lazy(() => import('./pages/admin/PermissionPage'));
let HealthCheckPage = React.lazy(() => import('./pages/admin/HealthCheckPage'));
let ResourceLimitPage = React.lazy(() => import('./pages/admin/ResourceLimitPage'));
// let WillSendEmailEventPage = React.lazy(() => import('./pages/admin/WillSendEmailEventPage'));
// let WillSendEmailPage = React.lazy(() => import('./pages/admin/WillSendEmailPage'));
// let DidSendEmailEventPage = React.lazy(() => import('./pages/admin/DidSendEmailEventPage'));
// let DidSendEmailPage = React.lazy(() => import('./pages/admin/DidSendEmailPage'));

let AllSearchMetricPage = React.lazy(() => import('./pages/admin/AllSearchMetricPage'));
let CompaniesCreateMetricPage = React.lazy(() => import('./pages/admin/CompaniesCreateMetricPage'));
let CompaniesSearchMetricPage = React.lazy(() => import('./pages/admin/CompaniesSearchMetricPage'));
let CompaniesViewMetricPage = React.lazy(() => import('./pages/admin/CompaniesViewMetricPage'));
let CompaniesWebsiteClickMetricPage = React.lazy(() => import('./pages/admin/CompaniesWebsiteClickMetricPage'));
let ConnectionsCreateMetricPage = React.lazy(() => import('./pages/admin/ConnectionsCreateMetricPage'));
let FollowersCreateMetricPage = React.lazy(() => import('./pages/admin/FollowersCreateMetricPage'));
let MessagesCreateMetricPage = React.lazy(() => import('./pages/admin/MessagesCreateMetricPage'));
let ProductsCreateMetricPage = React.lazy(() => import('./pages/admin/ProductsCreateMetricPage'));
let ProductsSearchMetricPage = React.lazy(() => import('./pages/admin/ProductsSearchMetricPage'));
let ProductsViewMetricPage = React.lazy(() => import('./pages/admin/ProductsViewMetricPage'));
let ProfileAppearMetricPage = React.lazy(() => import('./pages/admin/ProfileAppearMetricPage'));
let ResetPasswordMetricPage = React.lazy(() => import('./pages/admin/ResetPasswordMetricPage'));
let RoomsCreateMetricPage = React.lazy(() => import('./pages/admin/RoomsCreateMetricPage'));
let UsersConfirmMetricPage = React.lazy(() => import('./pages/admin/UsersConfirmMetricPage'));
let UsersCreateMetricPage = React.lazy(() => import('./pages/admin/UsersCreateMetricPage'));
let UsersLoginMetricPage = React.lazy(() => import('./pages/admin/UsersLoginMetricPage'));
let CompaniesBannerCreatePage = React.lazy(() => import('./pages/admin/CompaniesBannerCreatePage'));
let CompaniesLogoCreatePage = React.lazy(() => import('./pages/admin/CompaniesLogoCreatePage'));
let CompanyGroupsCreateMetricPage = React.lazy(() => import('./pages/admin/CompanyGroupsCreateMetricPage'));
let CompanyGroupPostsCreateMetricPage = React.lazy(() => import('./pages/admin/CompanyGroupPostsCreateMetricPage'));
let CompanyGroupMembersCreateMetricPage = React.lazy(() => import('./pages/admin/CompanyGroupMembersCreateMetricPage'));
let FeedsCreateMetricPage = React.lazy(() => import('./pages/admin/FeedsCreateMetricPage'));
let UsersSessionMetricPage = React.lazy(() => import("./pages/admin/UsersSessionMetricPage"));
let UsersOnlineMetricPage = React.lazy(() => import("./pages/admin/UsersOnlineMetricPage"));
let OnlineUserByTimeMetricPage = React.lazy(() => import("./pages/admin/OnlineUserByTimeMetricPage"));
let CustomChartMetricPage = React.lazy(() => import("./pages/admin/CustomChartMetricPage"));
let FaqManagerPage = React.lazy(() => import("./pages/admin/FaqManagerPage"));
let MtzCouponPage = React.lazy(() => import("./pages/admin/MtzCouponPage"));
let PointRateManagerPage = React.lazy(() => import("./pages/admin/PointRateManagerPage"));
let PointPromotionManagerPage = React.lazy(() => import("./pages/admin/PointPromotionManagerPage"));
let ContactsCreateMetricPage = React.lazy(() => import('./pages/admin/ContactsCreateMetricPage'));
let CompanyGroupMembersInviteMetricPage = React.lazy(() => import('./pages/admin/CompanyGroupMembersInviteMetricPage'));
let CompanyGroupMembersJoinMetricPage = React.lazy(() => import('./pages/admin/CompanyGroupMembersJoinMetricPage'));
let CGEventsCreateMetricPage = React.lazy(() => import('./pages/admin/CGEventsCreateMetricPage'));
let CompanyGroupsViewMetricPage = React.lazy(() => import('./pages/admin/CompanyGroupsViewMetricPage'));
let CGEventsViewMetricPage = React.lazy(() => import('./pages/admin/CGEventsViewMetricPage'));
let CGEventsSearchMetricPage = React.lazy(() => import('./pages/admin/CGEventsSearchMetricPage'));
let CompanyGroupsSearchMetricPage = React.lazy(() => import('./pages/admin/CompanyGroupsSearchMetricPage'));
let EmailSchemaLayoutDetailPage = React.lazy(() => import('./pages/admin/EmailSchemaLayoutDetailPage'));
let EmailSchemaLayoutsPage = React.lazy(() => import('./pages/admin/EmailSchemaLayoutsPage'));
let SponsorshipSubscriptionsCreateMetricPage = React.lazy(() => import('./pages/admin/SponsorshipSubscriptionsCreateMetricPage'));
let MtzPlanSubscriptionCreateMetricPage = React.lazy(() => import('./pages/admin/MtzPlanSubscriptionCreateMetricPage'));
let CompanyGroupManagerPage = React.lazy(() => import('./pages/admin/CompanyGroupManagerPage'));
let ReportedContentPage = React.lazy(() => import('./pages/admin/ReportedContentPage'));
let CompanySyncPage = React.lazy(() => import('./pages/admin/CompanySyncPage'));
let CompanySyncDetailPage = React.lazy(() => import('./pages/admin/CompanySyncDetailPage'));
let MtzPlanSubscriptionSyncPage = React.lazy(() => import('./pages/admin/MtzPlanSubscriptionSyncPage'));
let MtzPlanSubscriptionSyncDetailPage = React.lazy(() => import('./pages/admin/MtzPlanSubscriptionSyncDetailPage'));
let ProductSyncPage = React.lazy(() => import('./pages/admin/ProductSyncPage'));
let ProductSyncDetailPage = React.lazy(() => import('./pages/admin/ProductSyncDetailPage'));
let ExceptionPage = React.lazy(() => import('./pages/admin/ExceptionPage'));
let BillingPage = React.lazy(() => import('./pages/admin/BillingPage'));
let CompanyGroupMembersRequestMetricPage = React.lazy(() => import('./pages/admin/CompanyGroupMembersRequestMetricPage'));
let UsersRetentionMetricPage = React.lazy(() => import("./pages/admin/UsersRetentionMetricPage"));
let UserExtraQuestionPage = React.lazy(() => import('./pages/admin/UserExtraQuestionPage'));
let UserExtraQuestionChoicePage = React.lazy(() => import('./pages/admin/UserExtraQuestionChoicePage'));
let ConnectionsAcceptMetricPage = React.lazy(() => import('./pages/admin/ConnectionsAcceptMetricPage'));

// granted pages
let GrantedStatsUserCreateReaderPage = React.lazy(() => import('./pages/granted/GrantedStatsUserCreateReaderPage'));
let GrantedStatsUserConfirmReaderPage = React.lazy(() => import('./pages/granted/GrantedStatsUserConfirmReaderPage'));
let GranteeDashboard = React.lazy(() => import('./pages/granted/GranteeDashboard'));

// chat pages
let ChatHomePage = React.lazy(() => import('./pages/chat/ChatHomePage'));
let ChatCallPage = React.lazy(() => import('./pages/chat/ChatCallPage'));

// user pages v2
let MyIntroPage2 = React.lazy(() => import('./pages/user/v2/MyIntroPage2'));
let MyIntroPage3 = React.lazy(() => import('./pages/user/v2/MyIntroPage3'));
let LoginPage = React.lazy(() => import('./pages/public/v2/LoginPage'));
let LinkedInCallbackPage = React.lazy(() => import('./pages/public/LinkedInCallbackPage'));
let LandingPageV2 = React.lazy(() => import('./pages/public/v2/LandingPage'));
let MyNetworkPage = React.lazy(() => import('./pages/user/v2/MyNetworkPage'));
let MyTicketOrderPage = React.lazy(() => import('./pages/user/MyTicketOrderPage'));
let MyProductListPage = React.lazy(() => import('./pages/user/v2/MyProductListPage'));

// public pages v2
let CompanyDetailPage = React.lazy(() => import('./pages/public/v2/CompanyDetailPage'));
let ProductDetailPage = React.lazy(() => import('./pages/public/v2/ProductDetailPage'));
let CompanyGroupPage = React.lazy(() => import('./pages/public/v2/CompanyGroupPage'));
let CompanyImagesPage = React.lazy(() => import('./pages/public/v2/CompanyImagesPage'));
let CompanyPostPage = React.lazy(() => import('./pages/public/v2/CompanyPostPage'));
let PostDetailPage = React.lazy(() => import('./pages/public/v2/PostDetailPage'));
let CompanyProductsPage = React.lazy(() => import('./pages/public/v2/CompanyProductsPage'));
let CompanyResourcePage = React.lazy(() => import('./pages/public/v2/CompanyResourcePage'));

// ----- MAIN ATTRS OF EACH ROUTER
// key: This is path
// component: React component to display
// layout: Layout the component will belong to

let routers = {
	// ----- COMPANY
	"/me/intro": {
		component: MyIntroPage2
	},
	"/me/v2/intro": {
		component: MyIntroPage3
	},
	"/me/notifications": {
		component: MyNotificationPage,
		layout: ParentLayout
	},
	"/me/connections/qr": {
		component: MyConnectionQRPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/connections": {
		component: MyConnectionsPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/followings": {
		component: MyFollowingsPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/company-watchlists/:companyWatchlistId": {
		component: MyCompanyWatchlistDetailPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/company-watchlists": {
		component: MyCompanyWatchlistsPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/product-watchlists": {
		component: MyProductWatchlistPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/mtz-files": {
		component: MyMtzFilePage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/mtz-files/requests": {
		component: MyMtzFileRequestPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/tag-alerts": {
		component: MyTagAlertPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/company": {
		component: MyCompanyAddPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/company/extra-info": {
		component: MyCompanyExtraInfoPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/extra-info": {
		component: MyExtraInfoPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/get-started": {
		component: GetStartedPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/stats/company-views": {
		component: MyCompanyViewStatsPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/stats/product-views": {
		component: MyProductViewStatsPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/stats/rfq-views": {
		component: MyRfqViewStatsPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/network": {
		component: MyNetworkPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/network/events/tickets/my-orders": {
		component: MyTicketOrderPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	// "/me/global-calendar": {
	// 	component: MyGlobalCalendarPage,
	// 	layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	// },
	"/me/advertisement/ad-campaigns": {
		component: MyAdCampaignsPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/advertisement/ad-campaigns/add": {
		component: MyAdCampaignAddPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/advertisement/ad-campaigns/:id/clicks": {
		component: MyAdClickPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={2} />
	},
	"/me/advertisement/ad-campaigns/:id": {
		component: MyAdCampaignDetailPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/advertisement/group-sponsors": {
		component: MyCompanyGroupSponsorPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	[ROUTES.MY_ADVERTISEMENTS]: {
		component: MyAdvertisementsPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	[ROUTES.MY_ORGANIC_LISTINGS]: {
		component: MyOrganicListingsPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	[ROUTES.MY_AD_TEMPALTES]: {
		component: MyAdTemplatesPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},

	// ---- ACCOUNT
	"/me/account": {
		component: MyAccountPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/account/removal": {
		component: MyAccountRemovalPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},

	// ----- CATALOG
	"/me/products/add": {
		component: MyProductAddPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/products/uploads": {
		component: MyProductUploadPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/products/list": {
		component: MyProductListPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/products": {
		component: MyProductsPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/product-menus": {
		component: MyProductMenusPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},

	// RFQ
	"/rfqs/:rfqId": {
		component: RfqDetailPage,
		layout: ParentLayout
	},
	"/me/rfqs/add": {
		component: MyRfqAddPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/rfqs": {
		component: MyRfqPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},

	// ----- CRM
	"/me/crm/contacts": {
		component: MyContactsPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/crm/contacts/upload-events": {
		component: MyContactUploadEventPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/crm/contact-groups": {
		component: MyContactGroupsPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/crm/contact-groups/:groupId/contacts": {
		component: MyContactGroupMemberPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/crm/tracking-codes/tcLogs": {
		component: MyTrackingCodeLogsPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/crm/tracking-codes/:tcId/referral-rewards": {
		component: MyReferralTrackingCodeRewardPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/crm/tracking-codes": {
		component: MyTrackingCodesPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/crm/form-builders/:formBuilderId/feedbacks": {
		component: MyFormBuilderFeedbacksPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/crm/form-builders": {
		component: MyFormBuildersPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={2} />
	},
	"/me/crm/email-campaigns": {
		component: MyEmailCampaignPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/crm/email-campaigns/:id": {
		component: MyEmailCampaignDetailPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/crm/email-layouts": {
		component: MyEmailLayoutPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/crm/rfis": {
		component: MyRfisPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/crm/company-notes": {
		component: MyCompanyNotesPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/crm": {
		component: MyCRMPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},

	// ----- COMPANY GROUPS
	"/me/groups/add": {
		component: MyCompanyGroupAddPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/groups/ad-banners": {
		component: MyCGAdBannersPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/groups": {
		component: MyCompanyGroupsPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/groups/members/invites": {
		component: MyCompanyGroupInvitesPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/groups/members/requests": {
		component: MyCompanyGroupRequestsPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/groups/sponsors/invites": {
		component: MyCompanyGroupSponsorInvitesPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},

	// ----- USER PAYMENT INFO
	"/me/billing/donations": {
		component: MyDonationPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/billing/mtz-points": {
		component: MyPointBalancePage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/billing/mtz-coupons": {
		component: MyCouponPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/billing": {
		component: MyBillingPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/billing/wallet": {
		component: MyWalletPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/billing/payment-accounts": {
		component: MyPaymentAccountPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/billing/subscriptions": {
		component: MySubscriptionPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/billing/subscriptions/change-plan": {
		component: MtzPlanSubChangerPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},
	"/me/billing/seller": {
		component: MyMtzSellerPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},

	// ----- STRIPE PAYMENT
	"/mtz-admin/stripe-customers": {
		component: StripeCustomerPage,
		layout: AdminLayout
	},
	"/mtz-admin/stripe-subscriptions": {
		component: StripeSubscriptionPage,
		layout: AdminLayout
	},
	"/stripe-payment-success": {
		component: MyPaymentSuccessPage,
		layout: props => <UserDashboardLayout2 {...props} colSpan={3} />
	},

	// ----- DASHBOARD
	"/me": {
		component: MyDashboardPage,
		layout: props => (<UserDashboardLayout2 {...props} colSpan={2} />)
	},

	// ----- User's Profile
	"/users/:profileName": {
		component: UserProfilePage,
		layout: ParentLayout
	},

	// ----- ADMIN METRICS
	"/mtz-admin/stats/all-search": {
		component: AllSearchMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/companies-create": {
		component: CompaniesCreateMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/companies-website-click": {
		component: CompaniesWebsiteClickMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/companies-view": {
		component: CompaniesViewMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/profile-appear": {
		component: ProfileAppearMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/products-view": {
		component: ProductsViewMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/connections-create": {
		component: ConnectionsCreateMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/connections-accept": {
		component: ConnectionsAcceptMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/followers-create": {
		component: FollowersCreateMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/users-create": {
		component: UsersCreateMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/users-confirm": {
		component: UsersConfirmMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/rooms-create": {
		component: RoomsCreateMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/messages-create": {
		component: MessagesCreateMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/reset-password": {
		component: ResetPasswordMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/products-create": {
		component: ProductsCreateMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/presences-online": {
		component: UsersOnlineMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/online-users-by-time": {
		component: OnlineUserByTimeMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/presences-session": {
		component: UsersSessionMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/users-retention": {
		component: UsersRetentionMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/users-login": {
		component: UsersLoginMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/products-search": {
		component: ProductsSearchMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/companies-search": {
		component: CompaniesSearchMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/chart": {
		component: CustomChartMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/company-logo-create": {
		component: CompaniesLogoCreatePage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/company-banner-create": {
		component: CompaniesBannerCreatePage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/company-groups-create": {
		component: CompanyGroupsCreateMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/company-group-posts-create": {
		component: CompanyGroupPostsCreateMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/company-group-members-create": {
		component: CompanyGroupMembersCreateMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/feeds-create": {
		component: FeedsCreateMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/contacts-create": {
		component: ContactsCreateMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/cg-events-create": {
		component: CGEventsCreateMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/company-group-members-join": {
		component: CompanyGroupMembersJoinMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/company-group-members-request": {
		component: CompanyGroupMembersRequestMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/company-group-members-invite": {
		component: CompanyGroupMembersInviteMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/cg-events-search": {
		component: CGEventsSearchMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/company-groups-search": {
		component: CompanyGroupsSearchMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/company-groups-view": {
		component: CompanyGroupsViewMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/cg-events-view": {
		component: CGEventsViewMetricPage,
		layout: AdminLayout
	},
	[ROUTES.ADMIN_SPONSORSHIP_SUBSCRIPTION_CREATE_STATS]: {
		component: SponsorshipSubscriptionsCreateMetricPage,
		layout: AdminLayout
	},
	"/mtz-admin/stats/mtz-plan-subscriptions-create": {
		component: MtzPlanSubscriptionCreateMetricPage,
		layout: AdminLayout
	},

	// ----- OTHER ADMIN PAGES	
	"/mtz-admin/email-schemas": {
		component: EmailSchemasPage,
		layout: AdminLayout
	},
	"/mtz-admin/email-schemas/:schemaId/layouts": {
		component: EmailSchemaLayoutsPage,
		layout: AdminLayout
	},
	"/mtz-admin/email-schemas/:schemaId/layouts/:layoutId": {
		component: EmailSchemaLayoutDetailPage,
		layout: AdminLayout
	},
	"/mtz-admin/email-schemas/:id": {
		component: EmailSchemaDetailPage,
		layout: AdminLayout
	},
	"/mtz-admin/users/email-campaigns": {
		component: EmailCampaignPage,
		layout: AdminLayout
	},
	"/mtz-admin/users/email-campaigns/triggers": {
		component: EmailCampaignTriggerPage,
		layout: AdminLayout
	},
	"/mtz-admin/users/email-campaigns/scheduleds": {
		component: EmailCampaignScheduledPage,
		layout: AdminLayout
	},
	"/mtz-admin/users/email-campaigns/onetimes": {
		component: EmailCampaignOnetimePage,
		layout: AdminLayout
	},
	"/mtz-admin/users/email-campaigns/groups/:groupId": {
		component: EmailCampaignGroupDetailPage,
		layout: AdminLayout
	},
	"/mtz-admin/users/email-campaigns/:campaignId": {
		component: EmailCampaignDetailPage,
		layout: AdminLayout
	},
	"/mtz-admin/users/email-campaigns/:campaignId/:triggerId": {
		component: EmailCampaignTriggerDetailPage,
		layout: AdminLayout
	},
	"/mtz-admin/users": {
		component: UserManagerPage,
		layout: AdminLayout
	},
	"/mtz-admin/syncs": {
		component: SyncPage,
		layout: AdminLayout
	},
	"/mtz-admin/syncs/users": {
		component: UserSyncPage,
		layout: AdminLayout
	},
	"/mtz-admin/syncs/users/:id": {
		component: UserSyncDetailPage,
		layout: AdminLayout
	},
	"/mtz-admin/syncs/companies": {
		component: CompanySyncPage,
		layout: AdminLayout
	},
	"/mtz-admin/syncs/companies/:id": {
		component: CompanySyncDetailPage,
		layout: AdminLayout
	},
	"/mtz-admin/syncs/products": {
		component: ProductSyncPage,
		layout: AdminLayout
	},
	"/mtz-admin/syncs/products/:id": {
		component: ProductSyncDetailPage,
		layout: AdminLayout
	},
	"/mtz-admin/syncs/mtz-plan-subscriptions": {
		component: MtzPlanSubscriptionSyncPage,
		layout: AdminLayout
	},
	"/mtz-admin/syncs/mtz-plan-subscriptions/:id": {
		component: MtzPlanSubscriptionSyncDetailPage,
		layout: AdminLayout
	},
	"/mtz-admin/companies": {
		component: CompanyManagerPage,
		layout: AdminLayout
	},
	"/mtz-admin/company-extra-questions": {
		component: CompanyExtraQuestionPage,
		layout: AdminLayout
	},
	"/mtz-admin/company-extra-questions/:questionId/choices": {
		component: CompanyExtraQuestionChoicePage,
		layout: AdminLayout
	},
	"/mtz-admin/user-extra-questions": {
		component: UserExtraQuestionPage,
		layout: AdminLayout
	},
	"/mtz-admin/user-extra-questions/:questionId/choices": {
		component: UserExtraQuestionChoicePage,
		layout: AdminLayout
	},

	"/mtz-admin/products": {
		component: ProductManagerPage,
		layout: AdminLayout
	},
	"/mtz-admin/sitemap": {
		component: SiteMapPage,
		layout: AdminLayout
	},
	// "/mtz-admin/cron-jobs": {
	// 	component: CronJobPage,
	// 	layout: AdminLayout
	// },
	"/mtz-admin/permissions": {
		component: PermissionPage,
		layout: AdminLayout
	},
	"/mtz-admin/health-check": {
		component: HealthCheckPage,
		layout: AdminLayout
	},
	"/mtz-admin/resource-limits": {
		component: ResourceLimitPage,
		layout: AdminLayout
	},
	// "/mtz-admin/will-send-email-events": {
	// 	component: WillSendEmailEventPage,
	// 	layout: AdminLayout
	// },
	// "/mtz-admin/will-send-email-events/:cronEventId": {
	// 	component: WillSendEmailPage,
	// 	layout: AdminLayout
	// },
	// "/mtz-admin/did-send-email-events": {
	// 	component: DidSendEmailEventPage,
	// 	layout: AdminLayout
	// },
	// "/mtz-admin/did-send-email-events/:cronEventId": {
	// 	component: DidSendEmailPage,
	// 	layout: AdminLayout
	// },
	"/mtz-admin/faqs": {
		component: FaqManagerPage,
		layout: AdminLayout
	},
	"/mtz-admin/mtz-coupons": {
		component: MtzCouponPage,
		layout: AdminLayout
	},
	"/mtz-admin/point-rates": {
		component: PointRateManagerPage,
		layout: AdminLayout
	},
	"/mtz-admin/point-promotions": {
		component: PointPromotionManagerPage,
		layout: AdminLayout
	},
	"/mtz-admin/company-groups": {
		component: CompanyGroupManagerPage,
		layout: AdminLayout
	},
	"/mtz-admin/reported-contents": {
		component: ReportedContentPage,
		layout: AdminLayout
	},
	"/mtz-admin/exceptions": {
		component: ExceptionPage,
		layout: AdminLayout
	},
	"/mtz-admin/billing": {
		component: BillingPage,
		layout: AdminLayout
	},
	"/mtz-admin": {
		component: NewAdminDashboardPage,
		layout: AdminLayout
	},

	// ----- GRANTED
	"/grantee/stats/user-create-stats/readers": {
		component: GrantedStatsUserCreateReaderPage,
		layout: GranteeLayout
	},
	"/grantee/stats/user-confirm-stats/readers": {
		component: GrantedStatsUserConfirmReaderPage,
		layout: GranteeLayout
	},
	"/grantee": {
		component: GranteeDashboard,
		layout: GranteeLayout
	},

	// ----- CHAT
	"/chat/calls": {
		component: ChatCallPage
	},
	"/chat/:chatRoomId": {
		component: ChatHomePage,
		layout: ChatLayout2
	},
	"/chat": {
		component: ChatHomePage,
		layout: ChatLayout2
	},

	// ----- AUTH
	"/auth/linkedin": {
		component: LinkedInCallbackPage
	},
	"/login": {
		component: LoginPage
	},
	"/logout": {
		component: LogoutPage
	},
	"/register": {
		component: RegisterPage,
		MTZSuccessHandler: () => {
			window.location.href = "/me/intro"
		}
	},
	"/register-success": {
		component: RegisterSuccessPage,
	},
	"/register-confirm": {
		component: RegisterConfirmPage,
		MTZSuccessHandler: () => {
			window.location.href = "/me/intro"
		}
	},
	"/register-confirm-resend": {
		component: RegisterConfirmResendPage
	},
	"/reset": {
		component: ResetPage
	},
	"/reset-confirm": {
		component: ResetConfirmPage
	},

	// ----- PUBLIC
	"/directory/events": {
		component: EventDirectoryPage,
		layout: ParentLayout
	},
	"/directory/groups": {
		component: GroupDirectoryPage,
		layout: ParentLayout
	},
	"/profile/:profileName/feeds": {
		component: CompanyFeedPage,
		layout: ParentLayout
	},
	"/profile/:profileName/products": {
		component: CompanyProductsPage,
		layout: ParentLayout
	},
	"/profile/:profileName/groups": {
		component: CompanyGroupPage,
		layout: ParentLayout
	},
	"/profile/:profileName/posts": {
		component: CompanyPostPage,
		layout: ParentLayout
	},
	"/posts/:companyPostId": {
		component: PostDetailPage,
		layout: ParentLayout
	},
	"/profile/:profileName/resources": {
		component: CompanyResourcePage,
		layout: ParentLayout
	},
	"/profile/:profileName/images": {
		component: CompanyImagesPage,
		layout: ParentLayout
	},
	"/profile/:profileName": {
		component: CompanyDetailPage,
		layout: ParentLayout
	},
	"/products/:profileName/:sku": {
		component: ProductDetailPage,
		layout: ParentLayout
	},
	"/companies/:companyId": {
		component: CompanyDetailPage,
		layout: ParentLayout
	},
	"/profile/:profileName/product-menus": {
		component: CompanyProductMenusPage,
		layout: ParentLayout
	},
	"/forms/:formBuilderId": {
		component: FormBuilderFillingPage,
		// layout: ParentLayout
	},
	"/groups/:profileName/rooms": {
		component: CompanyGroupRoomPage,
		layout: GroupLayout
	},
	"/groups/:profileName/resources": {
		component: CompanyGroupResourcePage,
		layout: GroupLayout
	},
	"/groups/:profileName/members": {
		component: CompanyGroupMembersPage,
		layout: GroupLayout
	},
	"/groups/:profileName/members/invites": {
		component: CGMemberInvitePage,
		layout: GroupLayout
	},
	"/groups/:profileName/members/invites/:inviteId": {
		component: CGMemberInviteDetailPage,
		layout: GroupLayout
	},
	"/groups/:groupProfileName/events/:eventProfileName": {
		component: CGEventDetailPage,
		layout: EventLayout
	},
	"/groups/:groupProfileName/events/:eventProfileName/sponsors": {
		component: CGEventSponsorPage,
		layout: EventLayout
	},
	"/groups/:profileName/events": {
		component: CGEventPage,
		layout: GroupLayout
	},
	"/groups/:profileName/stats/cg-sponsor-stats": {
		component: CGSponsorClickStatPage,
		layout: ParentLayout
	},
	"/groups/:profileName/stats/cg-member-stats": {
		component: CGMemberClickStatPage,
		layout: ParentLayout
	},
	"/tickets/:ticketId": {
		component: CGEventTicketOrderDetailPage,
		layout: ParentLayout
	},
	"/groups/:profileName/stats": {
		component: CGOwnerStatsPage,
		layout: ParentLayout
	},
	"/groups/:profileName/sponsors/:companyGroupSponsorId": {
		component: CompanyGroupSponsorAdPage,
		layout: ParentLayout
	},

	"/groups/:profileName/sponsors": {
		component: CompanyGroupSponsorPage,
		layout: GroupLayout
	},
	"/groups/:profileName/subscribe_success": {
		component: CompanyGroupSponsorSubscribeSuccessPage,
		layout: ParentLayout
	},
	"/groups/:profileName/about": {
		component: CompanyGroupDetailPage,
		layout: GroupLayout
	},
	"/groups/:profileName/categories": {
		component: CompanyGroupCategoryPage,
		layout: GroupLayout
	},

	"/groups/:profileName/categories/members/:categoryId": {
		component: CompanyGroupCategoryMembersPage,
		layout: GroupLayout
	},
	"/groups/:profileName": {
		component: CompanyGroupPostPage,
		layout: GroupLayout
	},
	"/groups/:profileName/posts/:companyGroupPostId": {
		component: CompanyGroupPostDetailPage,
		layout: ParentLayout
	},
	"/feeds/:feedId": {
		component: FeedDetailPage,
		layout: ParentLayout
	},
	"/mtz-news": {
		component: MtzNewsPage,
		layout: ParentLayout
	},

	// ----- HOME
	"/directory": {
		component: SiteDirectoryPage,
		layout: ParentLayout
	},
	"/directory/products": {
		component: ProductDirectoryPage,
		layout: ParentLayout
	},
	"/directory/companies": {
		component: CompanyDirectoryPage,
		layout: ParentLayout
	},
	"/about": {
		component: AboutUs,
		layout: ParentLayout
	},
	"/contact": {
		component: ContactUsPage,
		layout: ParentLayout
	},
	"/terms": {
		component: TermsAndUse,
		layout: ParentLayout
	},
	"/privacy": {
		component: PrivacyPolicy,
		layout: ParentLayout
	},
	"/search": {
		component: SearchPage,
		layout: ParentLayout
	},
	"/pricing": {
		component: window.location.hostname === 'mytradezone.com1' ? ComingSoonPage : PlansAndPricingPage, // v2
		layout: ParentLayout
	},
	// "/pricing/:planId": {
	// 	component: window.location.hostname === 'mytradezone.com1' ? ComingSoonPage : PlanSubscriptionPage, // v2
	// 	layout: ParentLayout
	// },
	"/pricing/:planId/:priceId": {
		component: window.location.hostname === 'mytradezone.com1' ? ComingSoonPage : MtzSubscriptionCheckoutPage, // v2
		layout: ParentLayout
	},
	"/pricing/:planId/:priceId/payment": {
		component: window.location.hostname === 'mytradezone.com1' ? ComingSoonPage : MtzSubscriptionPaymentPage, // v2
		layout: ParentLayout
	},
	"/pricing/:planId/:priceId/success": {
		component: window.location.hostname === 'mytradezone.com1' ? ComingSoonPage : MtzSubscriptionSuccessPage, // v2
		layout: ParentLayout
	},
	"/checkout/company-group-sponsorship-subscriptions/:priceId": {
		component: window.location.hostname === 'mytradezone.com1' ? ComingSoonPage : SponsorshipSubscriptionCheckoutPage, // v2
		layout: ParentLayout
	},
	"/welcome": { // v2
		component: LandingPageV2,
		layout: ParentLayout
	},
	"/v2/timeline": {
		component: UserTimelinePage,
		layout: ParentAuthLayout
	},
	"/timeline": {
		component: UserTimelinePage,
		layout: ParentAuthLayout
	},
	"/faqs/:profileName": {
		component: FaqDetailPage,
		layout: ParentLayout
	},
	"/faqs": {
		component: FaqPage,
		layout: ParentLayout
	},
	"/hashtag/:hashtag": {
		component: HashtagDetailPage,
		layout: ParentLayout
	},
	"/file-access/:mtzFileId": {
		component: MtzFileSharePage,
		layout: ParentLayout
	},
	"/": {
		component: HomePage2
	},
	"/**": {
		component: TermsAndUse,
		layout: ParentLayout
	}
}
export default routers;