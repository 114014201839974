import React from "react";

import Moment from "react-moment";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import AuthContext from "../../../../AuthContext";
import mtzApis from '../../../../services';
import MyWallet from "../../../user-service/mtzWallets/MyWallet";
import OneClickLoginSignUp from "../../../user-service/users/OneClickLoginSignUp";
import CompanyGroupMemberCategoryManager from "../../companyGroupCategories/CompanyGroupMemberCategoryManager";
const { companyService, paymentService } = mtzApis;

export default function CGJoinButton({ profileName }) {
  let [group, setGroup] = React.useState(null);
  const me = React.useContext(AuthContext);

  React.useEffect(() => {
    let mounted = true;
    init();
    return () => { mounted = false; };
  }, []);

  const init = async () => {
    let groups = await companyService.getCompanyGroups(new URLSearchParams(`profileName=${profileName}`));
    if (groups && groups.length > 0) {
      group = groups[0];

      let prices = await companyService.getCGMemberPlanPrices(new URLSearchParams(`companyGroupIds=${group.id}`));
      if (prices) {
        prices.sort((a, b) => a.cents - b.cents);
        group.prices = prices;
      }

      setGroup(group || undefined);
    }
  };

  return (
    <>
      {group === null && 'Loading...'}

      {
        group && me.userId !== group.ownerId &&
        (
          group.asPremium ?
            <SubscribeButton group={group} /> :
            <JoinReqButton group={group} />
        )
      }
    </>
  );
}

function SubscribeButton({ group }) {
  let [selected, setSelected] = React.useState(null);
  let [cards, setCards] = React.useState(null);
  let [billAddrs, setBillAddrs] = React.useState(null);
  let [sub, setSub] = React.useState(null);
  let [mem, setMem] = React.useState(null);
  let [wallet, setWallet] = React.useState(null);
  let [coupon, setCoupon] = React.useState('');
  let [calc, setCalc] = React.useState(null);
  const me = React.useContext(AuthContext);

  React.useEffect(() => {
    init();
  }, [group]);

  const init = async () => {
    // load mem
    let mems = await companyService.getCompanyGroupMembers(new URLSearchParams(`userIds=${me.userId}&companyGroupIds=${group.id}`));
    setMem(mems[0]);

    if (!me.userId)
      return;

    // load sub
    let subs = await companyService.getCGMemberPlanSubscriptions(new URLSearchParams(`userIds=${me.userId}&companyGroupIds=${group.id}`));
    if (subs && subs.length > 0) {
      sub = subs[0];

      sub.price = await companyService.getCGMemberPlanPriceById(sub.cgMemberPlanPriceId);

      setSub(sub);
    }

    // load cards
    cards = await paymentService.getCards(new URLSearchParams(``));
    setCards(cards);

    // load billing addresses
    billAddrs = await paymentService.getBillingAddresses(new URLSearchParams(``));
    setBillAddrs(billAddrs);

    // get wallet
    wallet = await paymentService.getMyWallet();
    setWallet(wallet || undefined);
  };

  const subscribe = async () => {
    mtzApis.startSpinningIcon();
    let created = await companyService.createCGMemberPlanSubscription({ cgMemberPlanPriceId: selected.id, couponCodes: calc.mtzSubscription.couponCodes });
    mtzApis.stopSpinningIcon();

    if (created) {
      alert(`Payment success! Thank you for your subscription to the group ${group.name}.`);
      window.location.reload();
    }
  };

  const updatePrice = async updateParams => {
    const ok = await window.createMtzConfirm(`This new plan will start in the next billing cycle, proceed?`);
    if (!ok) return;

    mtzApis.startSpinningIcon();
    let updated = await companyService.updateCGMemberPlanSubscriptionById(sub.id, updateParams);
    mtzApis.stopSpinningIcon();

    if (updated) {
      alert(`Membership plan updated`);
      window.location.reload();
    }
  };

  const leaveGroup = async () => {
    const ok = await window.createMtzConfirm(`Are you sure? If you have a group subscription, it will be cancelled immediately. Also, It will removed in the next billing cycle`);
    if (!ok) return;

    mtzApis.startSpinningIcon();
    let del = await companyService.deleteCompanyGroupMemberById(mem.id);

    if (del) {
      alert(`You have lost access to this group`);
      setTimeout(() => window.location.reload(), 500);
    }
    mtzApis.stopSpinningIcon();
  };

  const updateCancel = async update => {
    if (update.cancelled) {
      const ok = await window.createMtzConfirm(`After cancellation, your membership will remain until the end of the current billing cycle. Please confirm.`);
      if (!ok) return;
    }

    mtzApis.startSpinningIcon();
    let updated = await companyService.updateCGMemberPlanSubscriptionById(sub.id, update);
    if (updated) {
      alert(`Membership ${updated.cancelled ? 'Unsubscribed' : 'Re-subscribed'}`);
      setTimeout(() => window.location.reload(), 500);
    }
    mtzApis.stopSpinningIcon();
  };

  const retryPayment = async () => {
    mtzApis.startSpinningIcon();
    let updated = await companyService.updateCGMemberPlanSubscriptionById(sub.id, { _recharge: true });
    mtzApis.stopSpinningIcon();

    if (updated) {
      alert('Payment retry succeeded');
      setTimeout(() => window.location.reload(), 500);
    }
  };

  const selectPrice = async pr => {
    selected = pr;
    setSelected(selected);
    calcSubPrice();
  };

  const calcSubPrice = async () => {
    if (!selected) return;

    let codes = [];
    if (calc) {
      codes = !!calc.mtzSubscription.couponCodes && [...calc.mtzSubscription.couponCodes];
      if (coupon) codes.push(coupon);
    }

    let created = await paymentService.createMtzSubscriptionCalc({
      mtzSubscription: {
        cents: selected.cents,
        sellerUserId: group.ownerId,
        buyerUserId: me.userId,
        couponCodes: codes
      }
    });

    if (created)
      setCalc(created);
  };

  return (
    <>
      {
        (!mem || mem.role !== 'ADMIN') &&

        <>
          {mem === null && 'loading...'}

          {
            !sub &&
            <>
              <button data-target='#group-price-modal' data-toggle='modal' className={`btn btn-${mem ? 'secondary' : 'primary'}`}>
                subscribe
              </button>
              <div className="modal" id='group-price-modal'>
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="d-flex w-100">
                        <h5 className="flex-fill mtz-h5">
                          {
                            me.userId ?
                              'Select billing plan and subscribe:' :
                              'Please login or sign-up to subscribe to this group:'
                          }
                        </h5>
                        <button className="btn btn-sm rounded-circle" data-dismiss="modal"><span className="fa fa-close"></span></button>
                      </div>
                    </div>

                    <div className="modal-body">
                      {
                        (!group || !group.prices || group.prices.length === 0) ?
                          ('Subscription plan is not available on this plan yet. Please come back later.') :
                          (
                            me.userId ?
                              (
                                !selected ?
                                  <div className="list-group">
                                    {
                                      group.prices.map(pr => (
                                        <div key={pr.id} className="list-group-item">
                                          <div><b>Price:</b> ${pr.cents / 100}</div>
                                          <div><b>Payment cycle:</b> {pr.type === 'month' ? 'MONTHLY' : 'YEARLY'}</div>
                                          <div className="text-right">
                                            <button onClick={() => selectPrice(pr)} className="btn btn-sm btn-outline-primary">Select</button>
                                          </div>
                                        </div>
                                      ))
                                    }
                                  </div> :
                                  <div className="d-flex flex-column mtz-gap-16">
                                    <h4 className="mtz-h4 text-center text-success">Review and Payment</h4>

                                    <div className="alert alert-info mtz-rounded-16">
                                      <b>Please read:</b> Your wallet will be used to deduct money for the payment. In case your wallet balance is below the payment amount, your default credit card on file will be used to fill the balance of your wallet then deduct the payment. Note the minimum balance refill amount is $15. By clicking on Confirm & Pay, I agree to this terms and conditions.
                                    </div>

                                    <div className='border mtz-p-16 mtz-rounded-16'>
                                      <MyWallet />
                                    </div>

                                    {/* coupon */}
                                    <div className="border mtz-p-16 mtz-rounded-16 mtz-gap-8 d-flex flex-column">
                                      <h6 className="mtz-h6 text-muted">Apply coupon:</h6>
                                      <div>
                                        <div className="w-100 w-md-50">
                                          <div className="d-flex mtz-gap-4">
                                            <input onChange={e => {
                                              // sanitize coupon 6-12 chars
                                              let c = e.target.value.replace(/[^a-z0-9]/g, '').trim().toLowerCase();
                                              setCoupon(c);
                                            }} value={coupon || ''}
                                              className="form-control" type="text" placeholder="E.g. abc123" />
                                            <button className="btn btn-primary" onClick={calcSubPrice}>
                                              Apply</button>
                                          </div>
                                        </div>
                                      </div>
                                      <small>* Coupon only has lowercased letters and numbers</small>
                                      {
                                        !!calc && calc.mtzSubscription.finalCents < calc.mtzSubscription.cents &&
                                        calc.mtzSubscription.couponCodes && calc.mtzSubscription.couponCodes.length > 0 &&
                                        <div>
                                          <h6 className="mtz-h6"><u>Coupon applied:</u></h6>
                                          <div>
                                            {
                                              calc.mtzSubscription.couponCodes.map(c => (
                                                <div key={c} className="rounded">
                                                  &emsp;- {c} <i onClick={() => {
                                                    calc.mtzSubscription.couponCodes = calc.mtzSubscription.couponCodes.filter(cc => cc !== c);
                                                    setCalc(calc);
                                                  }} className="fa fa-trash text-danger mtz-cursor-pointer"></i>
                                                </div>
                                              ))
                                            }
                                          </div>
                                        </div>
                                      }
                                    </div>

                                    <div className='d-flex'>
                                      <span className='flex-grow-1'></span>
                                      <div className="alert alert-light mtz-rounded-16">
                                        <div><b>- Subscribing to group:</b> {group.name}</div>
                                        <div><b>- Subscribing on billing plan:</b> <span className="text-primary">${selected.cents / 100}</span> - {selected.type === 'month' ? 'MONTHLY' : 'YEARLY'}</div>
                                        <div><b>- Billing Cycle:</b> {selected.type.toUpperCase()}LY</div>
                                        <div className='mtz-h5'><b>- Price:</b> <span className='text-primary'>${calc && calc.mtzSubscription.finalCents / 100}</span></div>
                                      </div>
                                    </div>

                                    <div className="d-flex">
                                      <div>
                                        <button onClick={() => setSelected(null)} className="btn btn-secondary text-nowrap">Cancel and Go Back</button>
                                      </div>

                                      <span className="flex-fill"></span>

                                      {
                                        (!cards || cards.length === 0) && wallet.cents < selected.cents ?
                                          <div className='text-center text-danger mtz-h5'>
                                            Your wallet balance is insufficient, and you have no card on file. Please add a card to proceed.
                                          </div> :
                                          <div className='text-center'>
                                            <button onClick={subscribe} className="btn btn-primary">
                                              Confirm & Pay
                                            </button>
                                          </div>
                                      }
                                    </div>
                                  </div>
                              ) :
                              <OneClickLoginSignUp />
                          )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </>
          }

          {
            sub &&
            <>
              {
                mem ?
                  <>
                    <div className="dropdown">
                      <button data-toggle='dropdown'
                        className={`btn btn-${mem ? 'secondary' : 'primary'}`}>
                        {sub ? (sub.cancelled ? <span>group joined, but membership unsubscribed</span> : 'group subscribed') : 'group joined'}
                      </button>

                      <div className="dropdown-menu">
                        {
                          sub &&
                          <a className="dropdown-item" href="#" data-toggle='modal' data-target='#membership-manage-popup'>Manage member subscription</a>
                        }

                        {
                          sub && sub.cancelled &&
                          <a href='' className="dropdown-item" onClick={() => updateCancel({ cancelled: false })}>
                            Re-subscribe membership <span className="text-danger">(access removed <Moment fromNow>{sub.nextExpiredAt}</Moment>)</span>
                          </a>
                        }

                        <a className="dropdown-item text-danger" onClick={() => leaveGroup()} href="#">Leave group</a>
                      </div>
                    </div>

                    {
                      sub &&
                      <div className="modal" id='membership-manage-popup'>
                        <div className="modal-dialog modal-lg">
                          <div className="modal-content">
                            <div className="modal-header">
                              <div className="d-flex w-100">
                                <h5 className="mtz-h5 flex-fill">Update group membership subscription:</h5>
                                <button className="btn-sm btn" data-dismiss='modal'>
                                  <span className="fa fa-close"></span>
                                </button>
                              </div>
                            </div>

                            <div className="modal-body">
                              <div>
                                <h5 className="">Current subscription:</h5>
                                <div><b>Plan price:</b> ${sub.price && sub.price.cents / 100} {sub.type.toUpperCase() + 'LY'}</div>
                                <div><b>Subscription status:</b> {sub.cancelled ? <span className="text-danger">UNSUBSCRIBED</span> : <span className="text-success">SUBSCRIBED</span>}</div>
                                <div><b>Date expired:</b> <Moment fromNow>{sub.nextExpiredAt}</Moment></div>
                                <div><b>Date started:</b> <Moment fromNow>{sub.createdAt}</Moment></div>
                                <div>
                                  <b>Next billing cycle plan:</b> <span className='text-primary'> ${sub.price.cents / 100}/{sub.price.type.toLowerCase()}ly</span>
                                </div>
                              </div>

                              <hr />

                              <div>
                                <h5 className="">Change subscription plan:</h5>

                                <div className="d-flex mtz-gap-4">
                                  {
                                    group.prices.map(pr => (
                                      <div onClick={() => updatePrice({ _switchToPriceId: pr.id })}
                                        key={pr.id} className="btn btn-outline-primary">
                                        <b>Price:</b> ${pr.cents / 100} - {pr.type === 'month' ? 'MONTHLY' : 'YEARLY'}
                                      </div>
                                    ))
                                  }
                                </div>

                                <hr />
                                <div className="text-right">
                                  {
                                    !sub.cancelled ?
                                      <button className="btn btn-outline-danger" onClick={() => updateCancel({ cancelled: true })}>
                                        Unsubscribe membership
                                      </button> :
                                      <button className="btn btn-outline-success" onClick={() => updateCancel({ cancelled: false })}>
                                        Re-subscribe membership <span className="text-danger">(access removed <Moment fromNow>{sub.nextExpiredAt}</Moment>)</span>
                                      </button>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </> :
                  <>
                    <div>
                      {
                        sub.cancelled &&
                        <div>
                          <button className="btn btn-outline-danger" onClick={() => updateCancel({ cancelled: !sub.cancelled })}>
                            membership removed, subscription cancelled. Click to re-join
                          </button>
                        </div>
                      }

                      {
                        sub.paymentError &&
                        <div>
                          <div className="my-1 alert alert-danger mtz-rounded-16">
                            <div><b>Member-subscription error</b></div>
                            <div>{sub.paymentError}.</div>
                            <div>
                              Please click <NavLink to='/me/billing/payment-accounts' target='_blank'>
                                HERE
                              </NavLink> to update payment info.
                            </div>
                            <div>
                              Then click <button className="btn btn-sm btn-outline-primary" onClick={retryPayment}>Retry payment</button>.
                            </div>
                          </div>
                        </div>
                      }

                    </div>
                  </>
              }
            </>
          }
        </>
      }
    </>
  );
}

function JoinReqButton({ group }) {
  let [req, setReq] = React.useState(null);
  let [mem, setMem] = React.useState(null);
  let [showCategory, setShowCategory] = React.useState(false);
  const modalAddAccountRef = React.useRef();
  let me = React.useContext(AuthContext);


  React.useEffect(() => {
    let mounted = true;
    init(mounted);
    return () => { mounted = false };
  }, []);

  const init = async mounted => {
    let mems = await companyService.getCompanyGroupMembers(new URLSearchParams(`userIds=${me.userId}&companyGroupIds=${group.id}`));
    if (mounted) setMem(mems[0] || undefined);

    let reqs = await companyService.getCGMemberRequests(new URLSearchParams(`ownerId=${me.userId}&companyGroupId=${group.id}`));
    if (mounted) setReq(reqs[0] || undefined);
  };

  const joinGroup = async () => {
    if (!me || !me.userId) {
      const ok = await window.createMtzConfirm(<div>
        Please login or sign-up to join group. Proceed?
      </div>, 'Sure, take me to login', 'No, later');
      if (ok)
        window.open('/login', '_blank').focus();
      return;
    }

    mtzApis.startSpinningIcon();
    let created = await companyService.createCGMemberRequest({ companyGroupId: group.id });
    mtzApis.stopSpinningIcon();

    if (created) {
      if (!group.asMemberApprovalRequired) {
        let mems = await companyService.getCompanyGroupMembers(new URLSearchParams(`companyGroupIds=${group.id}&userIds=${me.userId}`));
        if (mems && mems.length > 0) {
          setMem(mems[0]);
          setShowCategory(true);
        }

      }
      else
        setReq(created);
    }
  };

  const leaveGroup = async () => {
    const ok = await window.createMtzConfirm(`Are you sure?`);
    if (!ok) return;

    mtzApis.startSpinningIcon();
    let del = await companyService.deleteCompanyGroupMemberById(mem.id);
    mtzApis.stopSpinningIcon();

    if (del) {
      setMem(undefined);
    }
  };

  const deleteReq = async () => {
    const ok = await window.createMtzConfirm(`Do you want to cancel this group-join request?`);
    if (!ok) return;

    mtzApis.startSpinningIcon();
    let del = await companyService.deleteCGMemberRequestById(req.id);
    mtzApis.stopSpinningIcon();

    if (del) {
      setReq(null);
    }
  };

  return (
    <>
      {
        !req && !mem &&
        (
          (!me || !me.userId) ?
            <>
              <button data-target='#modal-add-account' data-toggle='modal' className="btn btn-primary">
                join group
              </button>

              <div className="modal" id='modal-add-account' ref={modalAddAccountRef} data-backdrop='static'>
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="d-flex w-100">
                        <h5 className="mtz-h5 flex-fill">Login or sign-up to join group:</h5>
                        <button className="btn btn-sm close" onClick={() => modalAddAccountRef.current.click()}>
                          <span className="fa fa-close"></span>
                        </button>
                      </div>
                    </div>

                    <div className="modal-body">
                      <OneClickLoginSignUp />
                    </div>
                  </div>
                </div>
              </div>
            </> :
            <button onClick={joinGroup} className="btn btn-primary">
              join group
            </button>
        )
      }

      {
        req && !mem &&
        <button className="btn btn-secondary" onClick={deleteReq}>
          request sent
        </button>
      }

      {
        mem &&
        <button onClick={leaveGroup} className="btn btn-secondary">
          leave group
        </button>
      }
      {
        mem && showCategory &&
        <CompanyGroupMemberCategoryManager companyGroupId={mem.companyGroupId} companyGroupMemberId={mem.id} />

      }
    </>
  );
}