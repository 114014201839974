import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import React from "react";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import * as authUtil from "../../../../services/authUtil";

import * as constants from "../../../../services/config";

import Grid from "@material-ui/core/Grid";
import mtzApis from "../../../../services";

const { companyService } = mtzApis;

function LoginForm({ noCaption, redirectUri, title, reload }) {
  let [showPassword, setShowPassword] = React.useState();
  let [email, setEmail] = React.useState();
  let [password, setPassword] = React.useState();

  const loginHandler = async (event) => {
    event.preventDefault();

    mtzApis.startSpinningIcon();
    let req = await companyService.createAuthReq({
      type: "LOGIN",
      data: {
        email: email,
        password: password,
      }
    });
    mtzApis.stopSpinningIcon();

    if (req) {
      saveLoginToken(req);
    }
  };

  const saveLoginToken = async (req) => {
    if (!req.accessToken)
      return;
    let parsed = atob(req.accessToken.split('.')[1]);
    parsed = JSON.parse(parsed);
    authUtil.setLogin(parsed.email, { accessToken: req.accessToken, refreshToken: req.refreshToken });

    // test token
    let user = await companyService.getMyUser();
    if (!user) {
      alert('Invalid login');
      authUtil.removeLogin(parsed.email);
    }

    if (reload === true) {
      window.location.reload();
      return;
    }

    let params = new URLSearchParams(window.location.search);
    let redirectUrl = params.get("redirectUrl");

    if (redirectUri) {
      window.location.href = redirectUri;
      return;
    }

    if (redirectUrl) {
      window.location.href = redirectUrl;
      return;
    }
    if (req.asNew === true) {
      window.location.href = "/me/intro";
      return;
    }
    window.location.href = "/";
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <GoogleOAuthProvider clientId={constants.GOOGLE_CLIENT_ID}>
      <form className="mtz-card py-4" onSubmit={loginHandler}>
        {noCaption !== true && (
          <div className="">
            <h5 style={{ textAlign: "center" }}>{title || 'Welcome to the social network for businesses'}</h5>
          </div>
        )}

        <div className="form-group">
          <label>Email:</label>
          <input
            className="form-control"
            required
            value={email || ""}
            onChange={(event) => setEmail(event.target.value)}
            placeholder="Email"
          />
        </div>

        <div className="form-group">
          <label htmlFor="password" className="form-label">Password:</label>
          <div className="input-group">
            <input
              className="form-control"
              required
              type={showPassword ? "text" : "password"}
              value={password || ""}
              onChange={(event) => setPassword(event.target.value)}
              placeholder="Password"
            />
            <button className="btn rounded-0" type="button"
              onClick={handlePasswordVisibility}>
              <i className={"fa " + (showPassword ? "fa-eye" : "fa-eye-slash")}></i>
            </button>
          </div>
        </div>

        <div>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <button
                className="btn btn-primary w-100"
                type="submit"
              >
                <span className="txt-413">Sign in</span>
              </button>
            </Grid>
          </Grid>
        </div>

        <div className="mtz-gap-8 d-flex flex-column">
          <div className="d-flex flex-column align-items-center">
            <GoogleLoginForm onLogin={saveLoginToken} />
          </div>
          <div className="d-flex flex-column align-items-center">
            <LinkedinLoginForm onLogin={saveLoginToken} />
          </div>
        </div>
      </form>
    </GoogleOAuthProvider >
  );
}
function LinkedinLoginForm({ onLogin }) {
  const { linkedInLogin } = useLinkedIn({
    clientId: constants.LINKEDIN_CLIENT_ID,
    redirectUri: `${window.location.origin}/auth/linkedin`,
    scope: "profile,email,openid",
    onSuccess: (res) => {
      (async () => {
        let req = {
          socialToken: res,
          type: "LINKEDIN",
          redirectUrl: `${window.location.origin}/auth/linkedin`,
        };

        mtzApis.startSpinningIcon();
        req = await companyService.createSocialAuthReq(req);
        mtzApis.stopSpinningIcon();

        if (req) {
          if (onLogin) onLogin(req);
        }
      })();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <img
      onClick={linkedInLogin}
      src={linkedin}
      alt="Sign in with Linked In"
      style={{ maxWidth: "200px", cursor: "pointer" }}
    />
  );
}

function GoogleLoginForm({ onLogin }) {
  const googleLogin = useGoogleLogin({
    onSuccess: (res) => {
      (async () => {
        let req = {
          socialToken: res.access_token,
          type: "GOOGLE",
        };

        mtzApis.startSpinningIcon();
        req = await companyService.createSocialAuthReq(req);
        mtzApis.stopSpinningIcon();

        if (req) {
          if (onLogin) onLogin(req);
        }
      })();
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  return (
    <GoogleLogin
      width={200}
      onSuccess={googleLogin}
      onError={(err) => alert("Google login failed. Contact support@mytradezone.com")}
      useOneTap
    />
  );
}

export default LoginForm;
