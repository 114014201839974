import React from "react";
import AuthContext from "../../AuthContext";
import FooterHori from '../ParentLayout/FooterHori';
import './index.css';

class WithThreeColumnLayout extends React.Component {
    static contextType = AuthContext;


    render() {
        return (
            <div className="d-flex mtz-gap-16 justify-content-center WithThreeColumnLayout">
                <div className="d-none d-xl-flex flex-column mtz-gap-16 bot-left WithThreeColumnLayout-left-sidebar" style={{ width: '380px' }}>
                    <div className="vh-100 overflow-auto">
                        {this.props.left && <this.props.left />}
                    </div>

                    <div className="flex-grow-1"></div>

                    <div className="vh-100 overflow-auto">
                        <FooterHori />
                    </div>
                </div>

                <div className="WithThreeColumnLayout-content" style={{ width: '850px' }}>
                    {this.props.center && <this.props.center />}
                </div>

                <div className="d-none d-xl-flex vh-80 overflow-auto flex-column mtz-gap-16 WithThreeColumnLayout-right-sidebar" style={{ width: '400px' }}>
                    {this.props.right && <this.props.right />}
                </div>
            </div>
        );
    };
}

export default WithThreeColumnLayout;