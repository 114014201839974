import LocationOnIcon from "@material-ui/icons/LocationOn";
import GoogleMapReact from "google-map-react";
import { GoogleApiWrapper } from "google-maps-react";
import React from "react";
import Geocode from "react-geocode";
import PlacesAutocomplete from "react-places-autocomplete";
import { GOOGLE_API_KEY } from "../../../../services/config";
import * as countryUtil from "../../../../utils/countryUtil";
import "./style.css";

import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import AuthContext from "../../../../AuthContext";
import mtzApis from "../../../../services";
const { companyService } = mtzApis;

const Marker = ({ text }) => <div>{text}</div>;

const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || "";

function CGEventBasicInfoViewEdit({ cgEventId, profileName }) {
  let [cgEvent, setCGEvent] = React.useState(null);
  let [companyGroupMember, setCompanyGroupMember] = React.useState(null);
  let [changes, setChanges] = React.useState({});
  let [enteredAddress, setEnteredAddress] = React.useState("");
  let [googleAddress, setGoogleAddress] = React.useState({});
  let [times, setTimes] = React.useState([]);
  let [timeZones, setTimeZones] = React.useState([]);
  const editEventModal = React.useRef();
  const me = React.useContext(AuthContext);
  const modalEventMapRef = React.useRef();

  React.useEffect(() => {
    let mounted = true;

    (async () => {
      if (cgEventId) {
        const cgEvents = await companyService.getCGEvents(
          `cgEventIds=${cgEventId}`
        );
        cgEvent = cgEvents && cgEvents.length > 0 ? cgEvents[0] : null;
      }
      if (profileName) {
        const cgEvents = await companyService.getCGEvents(
          `profileNames=${profileName}`
        );
        cgEvent = cgEvents && cgEvents.length > 0 ? cgEvents[0] : null;
      }
      if (cgEvent === null) return;

      let eventAddr = cgEvent.address
        ? `${cgEvent.address}, ${cgEvent.city || ""}, ${cgEvent.state || ""
        }, ${cgEvent.country
          ? countryUtil.from2CharToFullname(cgEvent.country)
          : ""
        } ${cgEvent.zipCode || ""}`
        : "Address is not available";
      let gAddr = await getGoogleAddress(eventAddr);
      if (gAddr) {
        cgEvent.lat = gAddr.lat;
        cgEvent.lng = gAddr.lng;
      }

      setChanges({ type: cgEvent.type });

      let companyGroupMembers = await companyService.getCompanyGroupMembers(
        new URLSearchParams(`companyGroupIds=${cgEvent.companyGroupId}&userIds=${me.userId}`)
      );
      let myCompanyGroupMember =
        companyGroupMembers && companyGroupMembers.length > 0 ? companyGroupMembers[0] : null;
      if (mounted) setCompanyGroupMember(myCompanyGroupMember);

      cgEvent.companyGroup = await companyService.getCompanyGroupById(cgEvent.companyGroupId);

      if (mounted) setCGEvent(cgEvent);
      if (mounted) setGoogleAddress(getDefaultGoogleAddress(cgEvent));

      times = await companyService.getCGEventTimes();
      times.sort();
      if (mounted) setTimes(times);

      timeZones = await companyService.getCGEventTimeZones();
      timeZones.sort();
      if (mounted) setTimeZones(timeZones);
    })();

    return () => { mounted = false; };
  }, [profileName, cgEventId]);

  const updateCGEvent = async e => {
    e.preventDefault();
    let { lat, lng, streetName, streetNumber, ...filteredAddress } = googleAddress;
    changes = { ...changes, ...filteredAddress };
    mtzApis.startSpinningIcon();
    let updated = await companyService.updateCGEventById(cgEvent.id, changes);
    mtzApis.stopSpinningIcon();

    if (updated) {
      alert('Event updated');
      setCGEvent(updated);
      editEventModal.current.click();
    }
  };

  const handleAddressChange = (address) => {
    setEnteredAddress(address);
  };

  const handleAddressSelect = async (address) => {
    let googleAddress = await getGoogleAddress(address);
    setEnteredAddress(address);
    setGoogleAddress(googleAddress);
  };

  const getDefaultGoogleAddress = (cgEvent) => {
    let googleAddress = {
      country: cgEvent.country || "",
      zipCode: cgEvent.zipCode || "",
      state: cgEvent.state || "",
      city: cgEvent.city || "",
      address: cgEvent.address || "",
    };
    return googleAddress;
  };

  const getGoogleAddress = async address => {
    Geocode.setApiKey(GOOGLE_API_KEY);
    Geocode.setLanguage("en");

    try {
      let response = await Geocode.fromAddress(address);
      let googleAddress = {
        country: '',
        zipCode: '',
        state: '',
        city: '',
        address: '',
      };
      for (let i = 0; i < response.results[0].address_components.length; i++) {
        let componentName = response.results[0].address_components[i].types[0];
        switch (componentName) {
          case 'country':
            googleAddress.country = response.results[0].address_components[i].short_name;
            break;
          case 'postal_code':
            googleAddress.zipCode = response.results[0].address_components[i].short_name;
            break;
          case "administrative_area_level_1":
            googleAddress.state = response.results[0].address_components[i].short_name;
            break;
          case 'locality':
            googleAddress.city = response.results[0].address_components[i].short_name;
            break;
          case 'route':
            googleAddress.streetName = response.results[0].address_components[i].short_name;
            break;
          case 'street_number':
            googleAddress.streetNumber = response.results[0].address_components[i].short_name;
            break;
        }
      }

      if (googleAddress.city === googleAddress.state)
        googleAddress.state = null

      const { lat, lng } = response.results[0].geometry.location;
      googleAddress.lat = lat;
      googleAddress.lng = lng;

      googleAddress.address = (googleAddress.streetNumber || '') + " " + (googleAddress.streetName || '');

      return googleAddress;
    } catch (e) {
      console.log(e);
    }
  };

  const renderAddress = () => {
    return (
      <>
        {
          cgEvent.city &&
          <div className="d-flex mtz-gap-4">
            <b><i className="fa fa-map"></i> Address: </b>
            <div><NavLink to="#" data-toggle='modal' data-target='#modal-event-map'
              ref={modalEventMapRef} className="d-flex align-items-center">
              {`${cgEvent.address}, ${cgEvent.city || ""}, ${cgEvent.state || ""
                }, ${cgEvent.country ? countryUtil.from2CharToFullname(cgEvent.country) : ""
                } ${cgEvent.zipCode || ""}`}
            </NavLink></div>
          </div>
        }

        <div className="modal" id='modal-event-map'>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <div className="d-flex">
                  <h5 className="mtz-h5 flex-fill">Event address on map:</h5>
                  <button className="btn btn-sm rounded-circle" onClick={() => modalEventMapRef.current.click()}></button>
                </div>
              </div>

              <div className="modal-body">
                <div mb={1}>
                  <LocationOnIcon fontSize="small" className="text-secondary" />
                  &nbsp;
                  <span className="cg-event-address">
                    {cgEvent.address
                      ? `${cgEvent.address}, ${cgEvent.city || ""}, ${cgEvent.state || ""
                      }, ${cgEvent.country
                        ? countryUtil.from2CharToFullname(cgEvent.country)
                        : ""
                      } ${cgEvent.zipCode || ""}`
                      : "Address is not available"}
                  </span>
                </div>
                <div style={{ height: "200px", width: "100%" }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: GOOGLE_API_KEY, region: "cn" }}
                    defaultCenter={{
                      lat: parseFloat(cgEvent.lat || 0),
                      lng: parseFloat(cgEvent.lng || 0),
                    }}
                    defaultZoom={12}
                  >
                    <Marker
                      lat={cgEvent.lat}
                      lng={cgEvent.lng}
                      text={<span style={{ fontSize: '30px' }} className='text-danger fa fa-map-marker'></span>} />
                  </GoogleMapReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {cgEvent && (
        <div>
          <div className="d-flex">
            <div className="flex-fill py-3">
              <b className="mtz-h2">Event:</b> <span className="mtz-h2 text-primary">{cgEvent.name || ""}</span>
            </div>

            {companyGroupMember && companyGroupMember.role === 'ADMIN' && (
              <div>
                <button
                  className="btn btn-link"
                  data-toggle='modal' data-target='#edit-event-modal'
                >
                  Edit event info <span className="fa fa-edit"></span>
                </button>

                <div className="modal" id='edit-event-modal'>
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <div className="d-flex w-100">
                          <h5 className="mtz-h5 flex-fill">Editing event '{cgEvent.name}':</h5>
                          <button data-dismiss='modal' ref={editEventModal}
                            className="btn btn-sm rounded-circle">
                            <span className="fa fa-close"></span>
                          </button>
                        </div>
                      </div>

                      <div className="modal-body">
                        <form className="d-flex flex-column mtz-gap-16" onSubmit={updateCGEvent}>
                          <div className="">
                            <label>
                              Event type: <small className="text-danger">*required</small>
                            </label>

                            <div className="d-flex mtz-gap-16 justify-content-center">
                              <div className="form-check">
                                <input defaultChecked={cgEvent.type === 'ONLINE'} onChange={e => setChanges({ ...changes, type: e.target.checked ? 'ONLINE' : '' })}
                                  className="form-check-input" type='radio' name='event-type-radio' />
                                <label className="form-check-label">Online</label>
                              </div>

                              <div className="form-check">
                                <input defaultChecked={cgEvent.type === 'PHYSICAL'}
                                  onChange={e => setChanges({ ...changes, type: e.target.checked ? 'PHYSICAL' : '' })}
                                  className="form-check-input" type='radio' name='event-type-radio' />
                                <label className="form-check-label">Venue</label>
                              </div>

                              <div className="form-check">
                                <input defaultChecked={cgEvent.type === 'BOTH'}
                                  onChange={e => setChanges({ ...changes, type: e.target.checked ? 'BOTH' : '' })}
                                  className="form-check-input" type='radio' name='event-type-radio' />
                                <label className="form-check-label">Both</label>
                              </div>
                            </div>
                          </div>

                          {
                            changes.type != 'ONLINE' &&
                            <div className="mtz-rounded-16">
                              <PlacesAutocomplete
                                value={enteredAddress}
                                onChange={handleAddressChange}
                                onSelect={handleAddressSelect}
                              >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                  <div>
                                    <div>
                                      <label>
                                        <b>Event Venue Address</b>
                                      </label>
                                      <input
                                        {...getInputProps({
                                          placeholder: "Search address on Google...",
                                          className: "form-control location-search-input",
                                        })}
                                      />
                                    </div>
                                    <div className="list-group autocomplete-dropdown-container">
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                          ? "bg-light text-primary"
                                          : "bg-light text-dark";

                                        return (
                                          <div
                                            {...getSuggestionItemProps(suggestion, {
                                              className: className + " mtz-cursor-pointer list-group-item",
                                            })}
                                            key={suggestion.placeId}
                                          >
                                            <span>{suggestion.description}</span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            </div>
                          }

                          <div className="d-flex flex-column">
                            <label>
                              Event name: <small className="text-danger">*required</small>
                            </label>
                            <input defaultValue={cgEvent.name || ''}
                              className="form-control"
                              onChange={(event) => setChanges({ ...changes, name: event.target.value })}
                              placeholder="E.g. Beach Music Concert"
                            />
                          </div>

                          <div className="form-group">
                            <label>
                              Start Date & Time&nbsp;
                              <small className="text-danger">(*required)</small>
                            </label>
                            <div className="d-flex mtz-gap-4">
                              <input defaultValue={cgEvent.startDate || ''}
                                onKeyDown={e => e.preventDefault()}
                                className="form-control"
                                type="date"
                                placeholder={"Start Date & Time"}
                                onChange={(e) => setChanges({ ...changes, startDate: e.target.value })}
                                min={(() => {
                                  let now = new Date();
                                  let min = now.toISOString().split('T')[0];
                                  return min;
                                })()}
                              />
                              <select value={changes.startTime || cgEvent.startTime || ''}
                                onChange={e => setChanges({ ...changes, startTime: e.target.value })}
                                className="form-control">
                                {times.map(o => <option key={o} value={o}>{o}</option>)}
                              </select>
                            </div>
                          </div>

                          <div className="form-group">
                            <label>End Date & Time:</label>
                            <div className="d-flex mtz-gap-4">
                              <input defaultValue={cgEvent.endDate}
                                onKeyDown={e => e.preventDefault()}
                                className="form-control"
                                type="date"
                                placeholder={"End Date & Time"}
                                onChange={(e) => setChanges({ ...changes, endDate: e.target.value })}
                                min={(() => {
                                  let now = new Date();
                                  let min = now.toISOString().split('T')[0];
                                  return min;
                                })()}
                              />
                              <select value={changes.endTime || cgEvent.endTime || ''}
                                onChange={e => setChanges({ ...changes, endTime: e.target.value })}
                                className="form-control">
                                {times.map(o => <option key={o} value={o}>{o}</option>)}
                              </select>
                            </div>
                          </div>

                          <button className="btn btn-primary">
                            <span className="save-button-text">Save</span>
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {(cgEvent.endTimeMillis < new Date().getTime()) && <div><b className="mtz-h5 text-danger">**Event Ended</b></div>}

          <div className="">
            <div className="d-flex flex-column mtz-gap-16">
              {
                cgEvent.companyGroup &&
                <div>
                  <b><i className="fa fa-building"></i> Event organizer:</b> {<NavLink to={`/groups/${cgEvent.companyGroup.profileName}`} className='mtz-h5'>
                  {cgEvent.companyGroup.name}
                  </NavLink>}
                </div>
              }

              <div>
                <b><i className="fa fa-map-marker"></i> Event type:</b> {cgEvent.type === 'BOTH' ? 'Online & Venue' : (cgEvent.type === 'ONLINE' ? 'Online' : 'Venue')}
              </div>

              <div>
                <b><i className="fa fa-calendar"></i> Start Date & Time:</b> {new Date(cgEvent.startTimeMillis).toLocaleString()}
              </div>

              <div>
                <b><i className="fa fa-calendar"></i> End Date & Time:</b> {new Date(cgEvent.endTimeMillis).toLocaleString()}
              </div>

              <span className="">
                {renderAddress()}
              </span>
            </div>
          </div>

          {/* {cgEvent.registrationUrl && (
            <div className="mtz-h6">
              <b>Registration Link:</b>&nbsp;
              <a href={cgEvent.registrationUrl}>{cgEvent.registrationUrl}</a>
            </div>
          )}
          {cgEvent.externalEventUrl && (
            <div className="mtz-h6">
              <b>External event url:</b>&nbsp;
              <a href={cgEvent.externalEventUrl}>{cgEvent.externalEventUrl}</a>
            </div>
          )} */}
        </div>
      )}
    </>
  );
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
})(CGEventBasicInfoViewEdit);
