import React from "react";

import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { NavLink } from "react-router-dom";
import AuthContext from "../../../../../AuthContext";
import mtzApis from "../../../../../services";
import { PAYMENT_SERVER_URL } from "../../../../../services/config";
import MyWallet from "../../../../user-service/mtzWallets/MyWallet";


let stripePromise = null;
if (PAYMENT_SERVER_URL.includes('localhost'))
    stripePromise = loadStripe('pk_test_51MC6L3A61qE8cuHxPJFWqpVDc6GtgDks7LvFuafSLWtBzqhTY3ZEyJBvPVSryYZ91tkgkzEsAEJb4zKetppjR5XY007pkmKWnV');
else if (PAYMENT_SERVER_URL.includes('mytradezone.com'))
    stripePromise = loadStripe('pk_live_51LuGhqH6fkJtSoVxsSVoDiSV7KpR3EhT0rCBwCHZmZVhgeBm140UiXiHstO3GG5F57KxGqopWT2c8bf7bVMrr2y700fViKFBYd');
else
    stripePromise = loadStripe('pk_test_51MC6L3A61qE8cuHxPJFWqpVDc6GtgDks7LvFuafSLWtBzqhTY3ZEyJBvPVSryYZ91tkgkzEsAEJb4zKetppjR5XY007pkmKWnV');

const { companyService, paymentService } = mtzApis;

function CGDonationButton({ profileName }) {
    let [donation, setDonation] = React.useState();
    let [amounts, setAmounts] = React.useState([]);
    let [params, setParams] = React.useState({});
    let [group, setGroup] = React.useState();
    let [step, setStep] = React.useState(1);
    let [calc, setCalc] = React.useState();
    let [cardErr, setCardErr] = React.useState();
    let [paymentMethod, setPaymentMethod] = React.useState("wallet");
    let modalCreateDonationPaymentRef = React.useRef();
    const me = React.useContext(AuthContext);
    const stripe = useStripe();
    const elements = useElements();

    React.useEffect(() => {
        init();
    }, []);

    const init = async () => {
        let groups = await companyService.getCompanyGroups(new URLSearchParams({ profileNames: profileName }));
        let group = groups[0];
        if (group) {
            setGroup(group);
            let donations = await companyService.getCGDonations(new URLSearchParams({ companyGroupIds: group.id }));
            donation = donations[0];

            if (donation) {
                setDonation(donation);
                let amounts = await companyService.getCGDonationPrices(new URLSearchParams({ cgDonationIds: donation.id }));
                setAmounts(amounts);
            }
        }
    }

    const createDonationPayment = async () => {
        let card = null;
        if (calc) {
            card = elements.getElement(CardElement);
            const result = await stripe.createToken(card);
            if (result.error) {
                setCardErr(result.error.message);
                return;
            }
            params.paymentSource = result.token.id;
        }

        if (!params.buyerUserFirstname) {
            setCardErr("First name is required");
            return;
        }

        if (!params.buyerUserLastname) {
            setCardErr("Last name is required");
            return;
        }

        if (!params.buyerUserEmail) {
            setCardErr("Email is required");
            return;
        }

        if (card)
            card.clear();

        mtzApis.startSpinningIcon();
        let created = await companyService.createCGDonationPayment(params);
        mtzApis.stopSpinningIcon();

        if (created) {
            if (me && me.userId) {
                const ok = await window.createMtzConfirm(<div>
                    <div className="text-center text-success mtz-h5">
                        Thank you so much for your donation!
                    </div>
                </div>, "Check my donations", "Close");
                if (ok) {
                    window.open("/me/billing/donations", "_blank");
                }
            } else {
                const ok = await window.createMtzConfirm(<div>
                    <div className="text-center text-success mtz-h5">
                        Thank you so much for your donation!. Login to explore more features?
                    </div>
                </div>, "Go to login", "Close");
                if (ok) {
                    window.open("/login", "_blank");
                }
            }

            setStep(1);
            modalCreateDonationPaymentRef.current.click();
        }
    }

    const getPaymentCalc = async (paymentMethod) => {
        setPaymentMethod(paymentMethod);

        if (paymentMethod === "card") {
            let mtzOrder = {
                lineItems: [{ cents: amounts.find(amount => amount.id === params.cgDonationPriceId).cents, quantity: 1 }],
                sellerUserId: group.ownerId,
                buyerUserEmail: me.email,
                paymentSource: 'random-token' // This is a random token for calculating the payment
            };
            calc = await paymentService.createMtzOrderCalc({ mtzOrder });
            setCalc(calc);
        } else
            setCalc();
    }

    if (!me || !group || !donation || !donation.asEnabled)
        return <></>;

    if (me.userId === group.ownerId)
        return <></>;

    return (
        <>
            <button className="btn btn-primary" data-toggle="modal" ref={modalCreateDonationPaymentRef}
                data-target="#modal-create-donation-payment">
                donate
            </button>

            <div className="modal fade" id="modal-create-donation-payment">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title mtz-h4">
                                Donate to the group '{group.name}'
                            </h4>
                            <button type="button" className="close"
                                onClick={() => { modalCreateDonationPaymentRef.current.click() }}>&times;</button>
                        </div>

                        <div className="modal-body">
                            {
                                step === 1 &&
                                <>
                                    <div className="form-group">
                                        <label>Select amount to donate:</label>
                                        {
                                            amounts.length > 0 ?
                                                <div className="d-flex mtz-gap-16 align-items-center flex-wrap justify-content-center">
                                                    {
                                                        amounts.map((amount, index) => {
                                                            return (
                                                                <div key={index} className="form-check">
                                                                    <input type="radio" className="form-check-input" id={`cg-donation-price-to-payment-${amount.id}`}
                                                                        checked={params.cgDonationPriceId === amount.id}
                                                                        onChange={() => { setParams({ ...params, cgDonationPriceId: amount.id }) }} />
                                                                    <label className="form-check-label" htmlFor={`cg-donation-price-to-payment-${amount.id}`}>
                                                                        ${amount.cents / 100}
                                                                    </label>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div> :
                                                <div className="text text-muted">
                                                    There are no donation amounts available
                                                </div>
                                        }
                                    </div>

                                    <div className="text-center">
                                        <button type="button" className="btn btn-primary"
                                            disabled={amounts.length === 0 || !amounts || !params.cgDonationPriceId}
                                            onClick={() => setStep(2)}
                                        >
                                            Next, select payment method
                                        </button>
                                    </div>
                                </>
                            }

                            {
                                step === 2 &&
                                <>
                                    <div>
                                        <button type="button" className="btn btn-secondary" onClick={() => setStep(1)}>
                                            Back
                                        </button>
                                    </div>

                                    <br />

                                    <div>
                                        <div className="form-group alert alert-secondary">
                                            <label>Payment method:</label>
                                            <select className="form-control" value={paymentMethod}
                                                onChange={(e) => getPaymentCalc(e.target.value)}>
                                                <option value="wallet">MyTradeZone wallet</option>
                                                <option value="card">Card</option>
                                            </select>
                                        </div>

                                        <hr />

                                        {
                                            paymentMethod === "card" ?
                                                <div className="alert alert-secondary">
                                                    <div>
                                                        <div className="mtz-h5">
                                                            <u>Payment Card Info:</u>
                                                        </div>
                                                        <small className="text-danger">
                                                            * Enter your card info below. We will not store your card information. Service
                                                            fee may apply if you pay with card.
                                                        </small>
                                                    </div>

                                                    <div className="alert alert-light mt-3">
                                                        <CardElement />
                                                    </div>

                                                    <div className="mt-3">
                                                        <div className="mtz-h5"><u>Billing Info:</u></div>

                                                        <div className="form-group">
                                                            <label>First name:</label>
                                                            <input type="text" className="form-control"
                                                                onChange={(e) => setParams({ ...params, buyerUserFirstname: e.target.value })} />
                                                        </div>

                                                        <div className="form-group">
                                                            <label>Last name:</label>
                                                            <input type="text" className="form-control"
                                                                onChange={(e) => setParams({ ...params, buyerUserLastname: e.target.value })} />
                                                        </div>

                                                        <div className="form-group">
                                                            <label>Email:</label>
                                                            <input type="email" className="form-control"
                                                                onChange={(e) => setParams({ ...params, buyerUserEmail: e.target.value })} />
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div className="alert alert-secondary">
                                                    <MyWallet />
                                                </div>
                                        }

                                        <hr />

                                        {
                                            paymentMethod === "wallet" && (!me || !me.userId) &&
                                            <div className="alert alert-danger">
                                                You need to login to use your wallet. Otherwise, please select card as payment method.
                                            </div>
                                        }

                                        {
                                            ((paymentMethod === "wallet" && me && me.userId) ||
                                                paymentMethod === 'card') &&
                                            <>
                                                <div className="text-right">
                                                    <div className="d-inline-block text-left">
                                                        <h5>=== Review donation ===</h5>
                                                        {
                                                            !calc ?
                                                                <div>
                                                                    <div>
                                                                        <b>Amount:</b> ${amounts.find(amount => amount.id === params.cgDonationPriceId).cents / 100}
                                                                    </div>
                                                                    <div>
                                                                        <b>Service fee:</b> $0
                                                                    </div>
                                                                    <div className="border-top mtz-h4 text-danger">
                                                                        <b>Total:</b> ${amounts.find(amount => amount.id === params.cgDonationPriceId).cents / 100}
                                                                    </div>
                                                                </div> :
                                                                <div>
                                                                    <div>
                                                                        <b>Amount:</b> ${calc.mtzOrder.cents / 100}
                                                                    </div>
                                                                    <div>
                                                                        <b>Service fee:</b> ${calc.mtzOrder.meta.tranxFee / 100}
                                                                    </div>
                                                                    <div className="border-top-dark mtz-h4 text-danger">
                                                                        <b>Total:</b> ${calc.mtzOrder.finalCents / 100}
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>

                                                <br />

                                                {
                                                    cardErr &&
                                                    <div className='text-danger font-weight-bold'>* ERROR: {cardErr}</div>
                                                }
                                                <button type="button" className="btn btn-primary w-100"
                                                    onClick={createDonationPayment}>
                                                    Donate
                                                </button>
                                            </>
                                        }
                                    </div>
                                </>
                            }

                            <hr />

                            <div className="text-right">
                                <small>
                                    <NavLink to="/me/billing/donations" target="_blank">
                                        Check my donations
                                    </NavLink>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

const CGDonationButtonWrapper = props => <Elements stripe={stripePromise}><CGDonationButton {...props} /></Elements>;
export default CGDonationButtonWrapper;