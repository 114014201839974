import React from 'react';

import Moment from 'react-moment';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import AuthContext from '../../../../AuthContext';
import mtzApis from '../../../../services';
import MyWallet from '../../../user-service/mtzWallets/MyWallet';

const { companyService, paymentService } = mtzApis;

function CGSponsorSubscriptionButton({ profileName, companyGroupId }) {
    let [group, setGroup] = React.useState(null);
    let [plans, setPlans] = React.useState(null);
    let [myMem, setMyMem] = React.useState(null);
    let [sub, setSub] = React.useState();
    let [mySponsor, setMySponsor] = React.useState(null);
    const me = React.useContext(AuthContext);
    const modalSponsorPlanSelectRef = React.useRef();
    const modalSponsorManagerRef = React.useRef();

    React.useEffect(() => {
        let isMounted = true;
        init(isMounted);
        return () => { isMounted = false; };
    }, []);

    const init = async (isMounted) => {
        if (profileName) {
            let groups = await companyService.getCompanyGroups(new URLSearchParams(`profileName=${profileName}`));
            if (groups && groups.length > 0) {
                group = groups[0];
                if (isMounted) setGroup(group);
            }
        } else if (companyGroupId) {
            let groups = await companyService.getCompanyGroups(new URLSearchParams(`companyGroupId=${companyGroupId}`));
            if (groups && groups.length > 0) {
                group = groups[0];
                if (isMounted) setGroup(group);
            }
        }

        if (group) {
            plans = await companyService.getCGSponsorPlans(new URLSearchParams(`companyGroupIds=${group.id}`));
            let prices = await companyService.getCGSponsorPlanPrices(new URLSearchParams(`companyGroupIds=${group.id}`));
            let map = {};
            prices.forEach(p => {
                if (!map[p.cgSponsorPlanId])
                    map[p.cgSponsorPlanId] = [];
                map[p.cgSponsorPlanId].push(p);
            });
            plans.forEach(pl => pl.prices = map[pl.id] || []);
            if (isMounted) setPlans(plans || undefined);

            let mems = await companyService.getCompanyGroupMembers(new URLSearchParams(`userIds=${me.userId}&companyGroupIds=${group.id}`));
            if (isMounted) setMyMem(mems && mems.length > 0 ? mems[0] : undefined);

            let sponsors = await companyService.getCompanyGroupSponsors(new URLSearchParams(`userIds=${me.userId}&companyGroupIds=${group.id}`));
            if (sponsors) setMySponsor(sponsors && sponsors.length > 0 ? sponsors[0] : undefined);

            let subs = await companyService.getCGSponsorPlanSubscriptions(new URLSearchParams(`companyGroupIds=${group.id}`));
            if (subs && subs.length > 0) {
                sub = subs[0];
                sub.price = await companyService.getCGSponsorPlanPriceById(sub.cgSponsorPlanPriceId);
                sub.plan = await companyService.getCGSponsorPlanById(sub.cgSponsorPlanId);
                setSub(sub);
            }
        }
    };

    const updateCancel = async update => {
        if (update.cancelled) {
            const ok = await window.createMtzConfirm(`After cancellation, your sponsorship will remain until the end of the current billing cycle. Please confirm.`);
            if (!ok) return;
        }

        mtzApis.startSpinningIcon();
        let updated = await companyService.updateCGSponsorPlanSubscriptionById(sub.id, update);
        if (updated) {
            alert(`Sponsorship ${updated.cancelled ? 'Unsubscribed' : 'Re-subscribed'}`);
            setTimeout(() => window.location.reload(), 500);
        }
        mtzApis.stopSpinningIcon();
    };

    const updatePrice = async (pl, pr) => {
        if (sub.plan.rank === pl.rank) {
            const ok = await window.createMtzConfirm(`You are changing the billing cycle, this will apply on the next billing. Proceed?`);
            if (!ok) return;

            mtzApis.startSpinningIcon();
            let updated = await companyService.updateCGSponsorPlanSubscriptionById(sub.id, { _switchToPriceId: pr.id });
            mtzApis.stopSpinningIcon();

            if (updated) {
                alert(`Sponsorship plan's billing cycle updated`);
                window.location.reload();
            }
        } else {
            if (sub.plan.rank > pl.rank) {
                const ok = await window.createMtzConfirm(`You are downgrading the plan from ${sub.plan.name} to ${pl.name}, this will be applied on the next billing. Proceed?`);
                if (!ok) return;

                mtzApis.startSpinningIcon();
                let updated = await companyService.updateCGSponsorPlanSubscriptionById(sub.id, { _downgradeToPriceId: pr.id });
                mtzApis.stopSpinningIcon();

                if (updated) {
                    alert(`Sponsorship plan downgraded`);
                    window.location.reload();
                }
            } else {
                const ok = await window.createMtzConfirm(`You are upgrading the plan from ${sub.plan.name} to ${pl.name}, this will be applied immediately, and you can access the extra features. Proceed?`);
                if (!ok) return;

                mtzApis.startSpinningIcon();
                let updated = await companyService.updateCGSponsorPlanSubscriptionById(sub.id, { _upgradeToPriceId: pr.id });
                mtzApis.stopSpinningIcon();

                if (updated) {
                    alert(`Sponsorship plan upgraded`);
                    window.location.reload();
                }
            }
        }
    };

    const leaveGroup = async () => {
        const ok = await window.createMtzConfirm(`Are you sure? If you have a sponsorship subscription, it will be cancelled immediately. Also, It will removed in the next billing cycle`);
        if (!ok) return;

        mtzApis.startSpinningIcon();
        let del = await companyService.deleteCompanyGroupSponsorById(mySponsor.id);

        if (del) {
            alert(`Your sponsorship has been removed`);
            setTimeout(() => window.location.reload(), 500);
        }
        mtzApis.stopSpinningIcon();
    };

    const retryPayment = async () => {
        mtzApis.startSpinningIcon();
        let updated = await companyService.updateCGSponsorPlanSubscriptionById(sub.id, { _recharge: true });
        mtzApis.stopSpinningIcon();

        if (updated) {
            alert('Payment retry succeeded');
            setTimeout(() => window.location.reload(), 500);
        }
    };


    if (!profileName && !companyGroupId)
        return <div>
            Please provide at least profileName or companyGroupId
        </div>

    if (myMem && myMem.role === 'ADMIN')
        return '';

    if (!me || !me.userId)
        return (
            <button className='btn btn-primary' onClick={() => alert('Please login to be a sponsor')}>
                become group sponsor
            </button>
        );

    return (
        <>
            {group === null && plans === null && myMem === null && <div>Loading...</div>}

            {group !== null && !group && 'Error: no group found'}

            {plans !== null && !plans && ''}

            {
                group && plans && !!myMem &&
                <>
                    {
                        !sub &&
                        <>
                            <button className='btn btn-primary' ref={modalSponsorPlanSelectRef}
                                data-toggle='modal' data-target='#modal-sponsor-plan-select'>
                                become group sponsor
                            </button>
                            <div className='modal' id='modal-sponsor-plan-select'>
                                <div className='modal-dialog modal-md'>
                                    <div className='modal-content'>
                                        <div className='modal-header'>
                                            <div className='d-flex w-100 align-items-center'>
                                                <h5 className='mtz-h5 flex-grow-1'>Sponsorship Subscription:</h5>
                                                <button className='btn btn-sm rounded-circle' onClick={() => modalSponsorPlanSelectRef.current.click()}>
                                                    <span className='fa fa-close'></span>
                                                </button>
                                            </div>
                                        </div>

                                        <div className='modal-body'>
                                            <SponsorSubscribeMenu group={group} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {
                        sub &&
                        <>
                            {
                                mySponsor ?
                                    <>
                                        <div className='dropdown'>
                                            <button data-toggle='dropdown'
                                                className={`btn btn-secondary`}>
                                                {sub.cancelled && 'sponsorship unsubscribed'}
                                                {!sub.cancelled && 'sponsorship subscribed'}
                                            </button>

                                            <div className="dropdown-menu">
                                                <a ref={modalSponsorManagerRef} className="dropdown-item" href="#" data-toggle='modal' data-target='#sponsorship-manage-popup'>Manage sponsorship subscription</a>

                                                {
                                                    sub.cancelled &&
                                                    <a href='' className="dropdown-item" onClick={() => updateCancel({ cancelled: false })}>
                                                        Re-subscribe sponsorship <span className="text-danger">(access removed <Moment fromNow>{sub.nextExpiredAt}</Moment>)</span>
                                                    </a>
                                                }

                                                <a className="dropdown-item text-danger" onClick={() => leaveGroup()} href="#">Remove sponsorship</a>
                                            </div>
                                        </div>

                                        <div className="modal" id='sponsorship-manage-popup'>
                                            <div className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <div className="d-flex w-100">
                                                            <h5 className="mtz-h5 flex-fill">Update group sponsorship subscription:</h5>
                                                            <button className="btn-sm btn" onClick={() => modalSponsorManagerRef.current.click()}>
                                                                <span className="fa fa-close"></span>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="modal-body">
                                                        <div>
                                                            <h5 className="text-muted"><u>Current subscription:</u></h5>
                                                            <div className='mtz-h5 text-primary'><b>Plan name:</b> {sub.plan.name}</div>
                                                            <div><b>Plan price:</b> ${sub && sub.price && sub.price.cents / 100} {sub.type.toUpperCase() + 'LY'}</div>
                                                            <div><b>Subscription status:</b> {sub.cancelled ? <span className="text-danger">UNSUBSCRIBED</span> : <span className="text-success">SUBSCRIBED</span>}</div>
                                                            <div><b>Date expired:</b> <Moment fromNow>{sub.nextExpiredAt}</Moment></div>
                                                            <div><b>Date started:</b> <Moment fromNow>{sub.createdAt}</Moment></div>
                                                            <div>
                                                                <b>Next billing cycle plan:</b> <span className='text-primary'>{(() => {
                                                                    let nextPriceId = sub.willDowngradeToPriceId ? sub.willDowngradeToPriceId : sub.cgSponsorPlanPriceId;
                                                                    let nextPlan = plans.find(p => {
                                                                        return p.prices.find(pr => pr.id === nextPriceId);
                                                                    });
                                                                    return nextPlan.name;
                                                                })()} ${(() => {
                                                                    let nextPriceId = sub.willDowngradeToPriceId ? sub.willDowngradeToPriceId : sub.cgSponsorPlanPriceId;
                                                                    let prices = [];
                                                                    plans.forEach(pl => prices = [...prices, ...(pl.prices || [])]);
                                                                    let nextPrice = prices.find(pr => pr.id === nextPriceId);
                                                                    return `${nextPrice.cents / 100}/${nextPrice.type.toLowerCase()}ly`;
                                                                })()}</span>
                                                            </div>
                                                        </div>

                                                        <hr />

                                                        <div>
                                                            <h5 className="text-muted"><u>Change subscription plan:</u></h5>

                                                            {
                                                                plans && plans.map(pl => (
                                                                    <div key={pl.id} className="border rounded my-1 mtz-p-16">
                                                                        <h5 className='mtz-h5'><b>Plan name:</b> <span className='text-primary'>{pl.name} - Rank: {pl.rank} <small className='text-danger mtz-b3'>(* Higher rank, more visible)</small></span></h5>
                                                                        <div className='d-flex mtz-gap-4'>
                                                                            {
                                                                                pl.prices.length > 0 ?
                                                                                    pl.prices.map(pr => (
                                                                                        <button onClick={() => updatePrice(pl, pr)}
                                                                                            disabled={sub.cgSponsorPlanPriceId === pr.id} key={pr.id}
                                                                                            className='btn btn-outline-primary'>
                                                                                            <div><b>Price:</b> ${pr.cents / 100} {pr.type === 'month' ? 'MONTHLY' : 'YEARLY'} {sub.cgSponsorPlanPriceId === pr.id && <small><i className='text-danger'>(currently subscribed)</i></small>}</div>
                                                                                        </button>
                                                                                    )) :
                                                                                    <span>No price found</span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }

                                                            <hr />
                                                            <div className="text-right">
                                                                {
                                                                    !sub.cancelled ?
                                                                        <button className="btn btn-outline-danger" onClick={() => updateCancel({ cancelled: true })}>
                                                                            Unsubscribe sponsorship
                                                                        </button> :
                                                                        <button className="btn btn-outline-success" onClick={() => updateCancel({ cancelled: false })}>
                                                                            Re-subscribe sponsorship <span className="text-danger">(access removed <Moment fromNow>{sub.nextExpiredAt}</Moment>)</span>
                                                                        </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </> :
                                    <>
                                        {
                                            sub.cancelled &&
                                            <button onClick={() => updateCancel({ cancelled: false })} className='btn btn-outline-danger btn-sm'>
                                                sponsorship removed and unsubscribed, be sponsor again?
                                            </button>
                                        }

                                        {
                                            sub.paymentError &&
                                            <div>
                                                <div className="my-1 alert alert-danger mtz-rounded-16">
                                                    <div><b>Member-subscription error</b></div>
                                                    <div>{sub.paymentError}.</div>
                                                    <div>
                                                        Please click <NavLink to='/me/billing/payment-accounts' target='_blank'>
                                                            HERE
                                                        </NavLink> to update payment info.
                                                    </div>
                                                    <div>
                                                        Then click <button className="btn btn-sm btn-outline-primary" onClick={retryPayment}>Retry payment</button>.
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                            }
                        </>
                    }
                </>
            }
        </>
    );
}

function SponsorSubscribeMenu({ group }) {
    const [view, setView] = React.useState('list');
    let [plans, setPlans] = React.useState(null);
    let [active, setActive] = React.useState(null);
    let [activePrice, setActivePrice] = React.useState(null);
    let [cards, setCards] = React.useState(null);
    let [billAddrs, setBillAddrs] = React.useState(null);
    let [wallet, setWallet] = React.useState(null);
    let [calc, setCalc] = React.useState(null);
    let [coupon, setCoupon] = React.useState('');
    const me = React.useContext(AuthContext);
    const modalSponsorSubRef = React.useRef();

    // const toggleView = () => {
    //     setView(view === 'list' ? 'grid' : 'list');
    // };

    React.useEffect(() => {
        let mounted = true;

        (async () => {
            plans = await companyService.getCGSponsorPlans(new URLSearchParams(`companyGroupIds=${group.id}`));
            if (plans) {
                let reqs = plans.map(pl => companyService.getCGSponsorPlanSubscriptionStats(new URLSearchParams(`cgSponsorPlanIds=${pl.id}`)));
                let statList = (await Promise.allSettled(reqs)).map(res => res.value);
                for (let i = 0; i < plans.length; i++)
                    plans[i].subStats = statList[i];

                let prices = await companyService.getCGSponsorPlanPrices(new URLSearchParams(`companyGroupIds=${group.id}`));
                let map = {};
                prices.forEach(p => {
                    if (!map[p.cgSponsorPlanId])
                        map[p.cgSponsorPlanId] = [];
                    map[p.cgSponsorPlanId].push(p);
                });
                plans.forEach(pl => pl.prices = map[pl.id] || []);
            }
            if (mounted) setPlans(plans || []);

            if (me && me.userId) {
                // load cards
                cards = await paymentService.getCards(new URLSearchParams(``));
                if (mounted) setCards(cards);

                // load billing addresses
                billAddrs = await paymentService.getBillingAddresses(new URLSearchParams(``));
                if (mounted) setBillAddrs(billAddrs);

                // get wallet
                wallet = await paymentService.getMyWallet();
                if (mounted) setWallet(wallet || undefined);
            }
        })();

        return () => mounted = false;
    }, []);

    const selectPlan = async plan => {
        setActive(plan);
    };

    const selectPrice = async price => {
        activePrice = price;
        setActivePrice(activePrice);
        calcSubPrice();
    };

    const calcSubPrice = async () => {
        if (!active || !activePrice) return;

        let codes = [];
        if (calc) {
            codes = !!calc.mtzSubscription.couponCodes && [...calc.mtzSubscription.couponCodes];
            if (coupon) codes.push(coupon);
        }

        let created = await paymentService.createMtzSubscriptionCalc({
            mtzSubscription: {
                cents: activePrice.cents,
                sellerUserId: group.ownerId,
                buyerUserId: me.userId,
                couponCodes: codes
            }
        });

        if (created)
            setCalc(created);
    };

    const subscribe = async e => {
        mtzApis.startSpinningIcon();
        let created = await companyService.createCGSponsorPlanSubscription({ cgSponsorPlanPriceId: activePrice.id, couponCodes: calc.mtzSubscription.couponCodes });
        mtzApis.stopSpinningIcon();
        if (created) {
            alert(`Thank you for your subscription, we are processing you payment. We will send you an email when it is done.`);
            window.location.reload();
        }
    };

    return (
        <div className="mtz-card">

            <h5>Sponsorship Plans:</h5>

            {/* {
                plans && plans.length > 0 &&
                <div className="d-flex justify-content-between mb-3">
                    <button className={`btn btn-sm ${view === 'list' ? 'btn-secondary' : 'btn-outline-secondary'}`} onClick={toggleView}>
                        List View
                    </button>
                    <button className={`btn btn-sm ${view === 'grid' ? 'btn-secondary' : 'btn-outline-secondary'}`} onClick={toggleView}>
                        Grid View
                    </button>
                </div>
            } */}

            {plans === null && 'Loading...'}

            {plans && plans.length === 0 && 'No sponsorship plan found'}

            {view === 'list' && (
                <>
                    {plans && plans.length > 0 && (
                        <div className="list-group">
                            {plans.map(p => (
                                <div key={p.id} className="list-group-item d-flex flex-column mtz-gap-8">
                                    <div>
                                        <b>Name:</b> <span className='mtz-h4 text-muted'>
                                            {p.name}
                                        </span>
                                    </div>
                                    {p.description && <div><b>Description:</b> {p.description}</div>}
                                    <div><b>Number of subscriptions allowed:</b> {p.subscriptionMax}</div>
                                    <div>
                                        <div><b>Rank:</b> {p.rank} - <small className="">(*Higher rank, more visible. 1 is the lowest rank)</small></div>
                                    </div>
                                    <div><b>Number of remaining subscriptions :</b> {p.subscriptionMax - p.subStats.all.count}</div>
                                    <NavLink className='ml-auto mtz-h5' onClick={() => selectPlan(p)} data-toggle="modal"
                                        data-target="#modal-sponsor-sub" to="#" ref={modalSponsorSubRef}>
                                        Subscribe
                                    </NavLink>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
            {view === 'grid' && (
                <>
                    {/* Grid View */}
                    <div className="row d-inline-block d-md-flex flex-column">
                        {plans && plans.length > 0 && plans.map(p => (
                            <div key={p.id} className="col-12 col-md-6 mb-4 mtz-rounded-16">
                                <div key={p.id} className="list-group-item d-flex flex-column mtz-gap-8">
                                    <div>
                                        <b>Name:</b> <NavLink onClick={() => selectPlan(p)} data-toggle="modal"
                                            data-target="#modal-sponsor-sub" to="#" ref={modalSponsorSubRef}>
                                            {p.name}
                                        </NavLink>
                                    </div>
                                    {p.description && <div><b>Description:</b> {p.description}</div>}
                                    <div><b>Number of subscriptions allowed:</b> {p.subscriptionMax}</div>
                                    <div>
                                        <div><b>Rank:</b> {p.rank} - <small className="">(*Higher rank, more visible. 1 is the lowest rank)</small></div>
                                    </div>
                                    <div><b>Number of remaining subscriptions :</b> {p.subscriptionMax - p.subStats.all.count}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}

            <div className="modal" id='modal-sponsor-sub'>
                <div className="modal-lg modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="d-flex w-100">
                                <h5 className="mtz-h5 flex-fill">Select Subscription Payment Plans:</h5>
                                <button className='btn btn-sm rounded-circle' onClick={() => modalSponsorSubRef.current.click()}>
                                    <span className='fa fa-close'></span>
                                </button>
                            </div>
                        </div>
                        <div className="modal-body">
                            {
                                active && (
                                    !activePrice ?
                                        <div className="d-flex flex-column mtz-gap-16">
                                            {active.prices.length === 0 && 'No payment plan found. This sponsorship plan may not be ready yet.'}

                                            {
                                                active.prices.length > 0 &&
                                                <div className="list-group">
                                                    {
                                                        active.prices.map(pr => (
                                                            <div className="list-group-item" key={pr.id}>
                                                                <div><b>Price:</b> ${pr.cents / 100} - <b>Billing Cycle:</b> {pr.type.toUpperCase()}LY</div>
                                                                <div className="text-right">
                                                                    <button onClick={() => selectPrice(pr)}
                                                                        className="btn btn-outline-primary btn-sm">
                                                                        select this plan
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            }
                                        </div> :
                                        <div className="d-flex flex-column mtz-gap-16">
                                            <div>
                                                <button className="btn btn-secondary" onClick={() => selectPrice(null)}>
                                                    Cancel and Go Back
                                                </button>
                                            </div>

                                            <h5 className="text-center text-success">Review & Payment</h5>

                                            {/* <div className="border mtz-p-16 mtz-rounded-16">
                                                <h5 className='mtz-h5'><u>Payment Methods:</u></h5>

                                                <div>
                                                    {cards === null && 'Loading...'}

                                                    {
                                                        (cards && cards.length === 0) &&
                                                        <div className='text-danger'>
                                                            *We can find your payment method. Please add your card to make payment.
                                                        </div>
                                                    }

                                                    <CardManager onChange={cards => setCards(cards)} />
                                                </div>

                                                <div>
                                                    {billAddrs === null && 'Loading...'}

                                                    {
                                                        (billAddrs && billAddrs.length === 0) &&
                                                        <div className='text-danger'>
                                                            *We can find your billing address. Please add one to proceed.
                                                        </div>
                                                    }

                                                    <BillingAddressManager onChange={addrs => setBillAddrs(addrs)} />
                                                </div>
                                            </div> */}

                                            <div className="alert alert-info mtz-rounded-16">
                                                <b>Please read:</b> Your wallet will be used to deduct money for the payment. In case your wallet balance is below the payment amount, your default credit card on file will be used to fill the balance of your wallet then deduct the payment. Note the minimum balance refill amount is $15. By clicking on Confirm & Pay, I agree to this terms and conditions.
                                            </div>

                                            <div className='border mtz-p-16 mtz-rounded-16'>
                                                <MyWallet />
                                            </div>

                                            {/* coupon */}
                                            <div className="border mtz-p-16 mtz-rounded-16 mtz-gap-8 d-flex flex-column">
                                                <h6 className="mtz-h6 text-muted">Apply coupon:</h6>
                                                <div>
                                                    <div className="w-100 w-md-50">
                                                        <div className="d-flex mtz-gap-4">
                                                            <input onChange={e => {
                                                                // sanitize coupon 6-12 chars
                                                                let c = e.target.value.replace(/[^a-z0-9]/g, '').trim().toLowerCase();
                                                                setCoupon(c);
                                                            }} value={coupon || ''}
                                                                className="form-control" type="text" placeholder="E.g. abc123" />
                                                            <button className="btn btn-primary" onClick={calcSubPrice}>
                                                                Apply</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <small>* Coupon only has lowercased letters and numbers</small>
                                                {
                                                    !!calc && calc.mtzSubscription.finalCents < calc.mtzSubscription.cents &&
                                                    calc.mtzSubscription.couponCodes && calc.mtzSubscription.couponCodes.length > 0 &&
                                                    <div>
                                                        <h6 className="mtz-h6"><u>Coupon applied:</u></h6>
                                                        <div>
                                                            {
                                                                calc.mtzSubscription.couponCodes.map(c => (
                                                                    <div key={c} className="rounded">
                                                                        &emsp;- {c} <i onClick={() => {
                                                                            calc.mtzSubscription.couponCodes = calc.mtzSubscription.couponCodes.filter(cc => cc !== c);
                                                                            setCalc(calc);
                                                                        }} className="fa fa-trash text-danger mtz-cursor-pointer"></i>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            <div className='d-flex'>
                                                <span className='flex-grow-1'></span>
                                                <div className="alert alert-light mtz-rounded-16">
                                                    <div><b>- Sponsoring Group:</b> {group.name}</div>
                                                    <div><b>- Sponsorship Plan:</b> {active.name}</div>
                                                    <div><b>- Plan Rank:</b> {active.rank}</div>
                                                    <div><b>- Billing Cycle:</b> {activePrice.type.toUpperCase()}LY</div>
                                                    <div className='mtz-h5'><b>- Price:</b> <span className='text-primary'>${calc && calc.mtzSubscription.finalCents / 100}</span></div>
                                                </div>
                                            </div>

                                            {
                                                (!cards || cards.length === 0) && wallet.cents < activePrice.cents ?
                                                    <div className='text-center text-danger mtz-h5'>
                                                        Your wallet balance is insufficient, and you have no card on file. Please add a card to proceed.
                                                    </div> :
                                                    <div className='text-center'>
                                                        <button onClick={subscribe} className="btn btn-primary">
                                                            Confirm & Pay
                                                        </button>
                                                    </div>
                                            }
                                        </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CGSponsorSubscriptionButton;