import React from 'react';

import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import AuthContext from '../../../../AuthContext';
import mtzApis from "../../../../services";
import * as authUtil from '../../../../services/authUtil';

const { companyService, commonUtil } = mtzApis;

function MyProfileButton({ reduxUsers, vertical, noCaption }) {
  let [loggedIns, setLoggedIns] = React.useState(null);
  const me = React.useContext(AuthContext);

  const loadUserInfo = async () => {
    const auths = authUtil.getLogins();
    if (auths && auths.length > 0) {
      let emails = auths.map(entry => entry.email);
      loggedIns = await companyService.getUsers(`emails=${emails}`);
      let idUserMap = {};
      loggedIns.forEach(item => idUserMap[item.email] = item);
      loggedIns = emails.map(email => idUserMap[email]).filter(item => item);
    } else
      loggedIns = [];

    setLoggedIns(loggedIns);
  }

  React.useEffect(() => {
    if (!loggedIns)
      (async () => {
        loadUserInfo();
      })();
    else {
      let map = {};
      reduxUsers.forEach(u => map[u.id] = u);
      loggedIns = loggedIns.map(login => map[login.id] ? map[login.id] : login);
      setLoggedIns(loggedIns);
    }
  }, [reduxUsers]);

  const logoutAll = async () => {
    if (!await window.createMtzConfirm("Are you sure you want to logout ALL of your account(s) on MyTradeZone?"))
      return;
    authUtil.logoutAll();
    window.location.href = "/login";
  }

  const switchLogin = email => {
    authUtil.switchLogin(email);
    window.location.reload();
  }

  const removeLogin = async email => {
    const ok = await window.createMtzConfirm("Are you sure?");
    if (!ok) return;
    authUtil.removeLogin(email);

    if (authUtil.getLogins() && authUtil.getLogins().length > 0)
      window.location.reload();
    else
      window.location.href = "/login";
  }

  return (
    <span>
      {
        loggedIns && loggedIns.length === 0 &&
        <div className='d-flex'>
          <NavLink className="btn btn-primary btn-sm" to='/login'>
            SIGN IN
          </NavLink>
          <NavLink className="btn btn-outline-primary btn-sm" to='/register'>
            Join<span className="d-none d-sm-inline-block">&nbsp;now</span><span className="d-none d-md-inline-block">&nbsp;for free</span>
          </NavLink>
        </div>
      }

      {
        loggedIns && loggedIns.length > 0 &&
        <div className=''>
          <button className='btn btn-sm' data-toggle='dropdown'>
            <span className={(vertical === false ? 'd-flex' : 'flex-column d-flex align-items-center')}>
              <div className={"d-flex align-items-center"}>
                {
                  loggedIns[0].logo ?
                    <img style={{ width: '40px', height: '40px' }} className='rounded-circle border p-1' src={companyService.getBackendHost() + loggedIns[0].logo} /> :
                    <span className='mtz-h4 fa fa-user-circle'></span>
                }
              </div>
            </span>
          </button>

          <div className='dropdown-menu mtz-rounded-16'>
            <div className='mtz-card'>
              {
                loggedIns && loggedIns.map(
                  (user, idx) => (
                    <div key={user && user.id} className={"dropdown-item " + (user.id === me.userId ? ' bg-light rounded' : '')}
                      onClick={() => { switchLogin(user.email); }}
                    >
                      <div className={'d-flex mtz-gap-8 align-items-center'}>
                        <div className='mtz-cursor-pointer flex-fill d-flex mtz-gap-8 align-items-center'>
                          {
                            user.logo ?
                              <img className='rounded-circle border p-1' style={{ width: '40px', height: '40px' }} src={companyService.getBackendHost() + user.logo} /> :
                              <i style={{ width: 40, heigth: 40 }} className='fa mtz-h4 fa-user-circle'></i>
                          }

                          <small className="flex-fill">
                            <b>{user.firstname + " " + user.lastname}<br /></b>
                            <i>{user.email}</i>
                          </small>
                        </div>

                        <button className='text-danger fa fa-sign-out btn btn-sm rounded-circle'
                          onClick={(event) => {
                            event.stopPropagation();
                            removeLogin(user.email);
                          }}>
                        </button>
                      </div>
                    </div>
                  )
                )
              }

              <div className='dropdown-item'>
                <NavLink to={"/login"}>
                  <i className='fa fa-user-plus'></i> Login another account
                </NavLink>
              </div>

              <div className="dropdown-item" >
                <NavLink to='/reset'>
                  <i className='fa fa-key'></i> Reset password
                </NavLink>
              </div>

              <div className="dropdown-item" >
                <NavLink to='/me/account' className='text-dark'>
                  <i className='fa fa-cog'></i> Account settings
                </NavLink>
              </div>

              <div className="dropdown-item" >
                <button className='btn text-danger' onClick={logoutAll}>
                  <i className='fa fa-power-off'></i> Logout all account(s)
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    </span>
  );
}

const mapStateToProps = state => ({
  reduxUsers: state.users
});

const mapDispatchToProps = dispatch => ({

});

const MyProfileButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyProfileButton);

export default MyProfileButtonContainer;